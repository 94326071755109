import { type Template, TemplateTag, RenderTemplateOptions } from "./types";
import {
  getFinishTime,
  modifyDeepByName,
  updateStartElement,
  updateIntermediateProgressElements,
  updateFinishElement,
  setTracks,
  updateElementTimings,
  updateRootDurations,
} from "./libs";


export const renderTemplate = (options: RenderTemplateOptions): Template => {
  const { template, race, participant, moments, shotsUrl } = options;

  let updatedTemplate = modifyDeepByName(template, TemplateTag.FINISH_TIME, "text", getFinishTime(participant));
  updatedTemplate = modifyDeepByName(updatedTemplate, TemplateTag.PARTICIPANT_NAME, "text", participant.name);
  updatedTemplate = modifyDeepByName(updatedTemplate, TemplateTag.PARTICIPANT_BIB, "text", participant.bib);
  updatedTemplate = modifyDeepByName(updatedTemplate, TemplateTag.RACE_NAME, "text", race.name);

  updatedTemplate = updateStartElement(updatedTemplate, race, participant, moments, shotsUrl);
  updatedTemplate = updateIntermediateProgressElements(updatedTemplate, race, participant, moments, shotsUrl);
  updatedTemplate = updateFinishElement(updatedTemplate, race, participant, moments, shotsUrl);

  updatedTemplate = updateElementTimings(updatedTemplate);
  updatedTemplate = updateRootDurations(updatedTemplate);

  updatedTemplate = setTracks(updatedTemplate);
  return updatedTemplate;
};


