<script setup>
import { computed } from "vue";
import { query, where, collection } from "firebase/firestore";
import { useFirestore, useCollection } from "vuefire";
import RecognitionDialog from "./RecognitionDialog.vue";
import { RECOGNITION_STATUSES } from "../libs/shotHelpers";
const db = useFirestore();

const props = defineProps(["shotId", "race", "availableParticipants", "broadcastedMoments", "shotRecognitionStatus"]);
const emit = defineEmits([
  "approveRecognition",
  "denyRecognition",
  "resetRecognition",
  "removeRecognition",
  "toggleBroadcastedMoment",
  "recognitionManual",
  "addManualRecognitions",
]);

const recognitionsQuery = computed(() => {
  if (props.shotId) {
    return query(collection(db, "races", props.race.id, "recognitions"), where("shot.id", "==", props.shotId));
  }
});

const recognitions = useCollection(recognitionsQuery);
const recognitionsWithoutBibs = computed(() =>
  recognitions.value.filter((recognition) => !recognition.participant?.bib)
);

function isRecognitionOngoing() {
  return props.shotRecognitionStatus === RECOGNITION_STATUSES.PENDING;
}

function isRecognitionPersonOngoing(recognition) {
  return recognition.events.filter((event) => event.type === "bib-recognition" && event.subtype === "decision").length < 1;
}

function approveRecognition(participantId) {
  emit("approveRecognition", participantId);
}

function denyRecognition(participantId) {
  emit("denyRecognition", participantId);
}

function resetRecognition(participantId) {
  emit("resetRecognition", participantId);
}

function removeRecognition(participantId) {
  emit("removeRecognition", participantId);
}

function toggleBroadcastedMoment(momentId, isVisible) {
  emit("toggleBroadcastedMoment", momentId, isVisible);
}

function recognitionManual(participant) {
  emit("recognitionManual", participant);
}

function addManualRecognitions(bib) {
  emit("addManualRecognitions", bib);
}
</script>

<template>
  <div class="my-2 d-flex flex-wrap">
    <div class="flex-grow-0 font-weight-bold" style="font-size: 14px; line-height: 24px">AI recognition:</div>
    <div v-if="isRecognitionOngoing(recognitions)">
      <v-progress-circular indeterminate color="primary" size="x-small" class="ml-2"></v-progress-circular>
    </div>
    <div v-else class="flex-grow-0 mb-2" v-for="recognition in recognitionsWithoutBibs">
      <RecognitionDialog
        :race="race"
        :recognition-id="recognition.id"
        :available-participants="availableParticipants"
        :shot-id="shotId"
        :broadcastedMoments="broadcastedMoments"
        :shot-recognition="recognition"
        @add-manual-recognitions="addManualRecognitions"
        @recognition-manual="recognitionManual"
        @approveRecognition="approveRecognition"
        @denyRecognition="denyRecognition"
        @resetRecognition="resetRecognition"
        @removeRecognition="removeRecognition"
        @toggleBroadcastedMoment="toggleBroadcastedMoment"
      >
        <v-chip size="small" class="mx-2 cursor-pointer">
          <v-icon>mdi-account</v-icon>
          <template v-if="recognition.participant?.bib">
            <strong>{{ recognition.participant?.bib }}</strong>
          </template>
          <v-progress-circular
            v-if="isRecognitionPersonOngoing(recognition)"
            indeterminate
            color="primary"
            size="x-small"
            class="ml-1"
          ></v-progress-circular>
        </v-chip>
      </RecognitionDialog>
    </div>
    <div v-if="!isRecognitionOngoing(recognitions) && recognitionsWithoutBibs.length === 0" class="ml-2 font-weight-light" style="font-size: 90%; position: relative; top: 2px;">
      recognizer finished, there are no other recognized participants without bibs
    </div>
  </div>
</template>

<style scoped>
.bib-row:hover .add-bib-btn {
  opacity: 1;
}

.add-bib-btn {
  opacity: 0;
  transition: all 0.2s;
}
</style>
