<script setup>
import { shotsBaseUrl } from "../../config";
import { computed } from "vue";
import { useStorage, useClipboard } from "@vueuse/core";
import { useFirestore } from "vuefire";
import { doc, setDoc } from "firebase/firestore";
import { getCreatomateTemplate } from "./getCreatomateTemplate";

import { renderTemplate } from "../../../../../libs/creatomate";
import { convertTimestampsToDates } from "../../libs/convertTimestampsToDates";

const db = useFirestore();

const { copy } = useClipboard();

const RENDER_ENDPOINT = "https://api.creatomate.com/v1/renders";

const finishTime = computed(() => {
  return props.participant.formattedFinishTime || "";
});

const props = defineProps(["race", "participant", "tracks", "cameraPoints", "moments", "shots"]);

const creatomateApiKey = useStorage("creatomate-api-key", "");

const isRenderEnabled = computed(() => finishTime.value !== "" && creatomateApiKey.value !== "");

const track = computed(() => props.tracks.find((track) => track.id === props.participant.trackId));

async function render() {
  const template = await getTemplateSkeleton();

  try {
    const renderedTemplate = renderTemplate({
      template,
      race: props.race,
      participant: convertTimestampsToDates(props.participant),
      moments: props.moments,
      shotsUrl: `${shotsBaseUrl}${props.race.id}/`,
    });

    console.log("Rendered template", renderedTemplate);
    const requestOptions = getRequestOptions(renderedTemplate);
    console.log("REQUEST", RENDER_ENDPOINT, requestOptions);

    const respone = await fetch(RENDER_ENDPOINT, requestOptions);

    if (!respone.ok) {
      const error = await respone.json();
      throw new Error(JSON.stringify(error));
    }

    const data = await respone.json();
    console.log("RESPONSE", data);

    const [{ url }] = data;
    await copy(url);
    alert(`${url} copied to clipboard`);
  } catch (error) {
    console.error(error);
    alert(error.message);
    return;
  }
}

async function getTemplateSkeleton() {
  return JSON.parse(await getCreatomateTemplate(db, props.race.id, track.value.id));
}

function getRequestOptions(source) {
  return {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: `Bearer ${creatomateApiKey.value}` },
    body: JSON.stringify({ source }),
  };
}

async function updateFormattedTimeOfRunner(raceId, participantId, formattedTime) {
  await setDoc(
    doc(db, "races", raceId, "participants", participantId),
    { formattedFinishTime: formattedTime },
    { merge: true }
  );
}
</script>

<template>
  <v-container class="pa-0 my-4">
    <div class="mb-3"><strong>Post-race video rendering</strong></div>
    <v-row>
      <v-col class="flex-grow-0">
        <v-text-field
          style="width: 200px"
          label="Finish time"
          v-model="props.participant.formattedFinishTime"
          @update:model-value="
              async (formattedTime) => {
                await updateFormattedTimeOfRunner(props.race.id, props.participant.id, formattedTime);
              }"
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-btn :disabled="!isRenderEnabled" @click="render" target="_blank" color="cyan-darken-1" size="x-large"
          >Render</v-btn
        >
      </v-col>
      <v-col class="flex-grow-0">
        <v-dialog width="500">
          <template v-slot:activator="{ props }">
            <v-btn color="cyan-darken-1" size="large" v-bind="props" icon="mdi-cog" variant="plain"></v-btn>
          </template>

          <template v-slot:default="{ isActive }">
            <v-card title="Render settings">
              <v-card-text>
                <v-text-field label="Creatomate API key" v-model="creatomateApiKey"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Close" @click="isActive.value = false"></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
