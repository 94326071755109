<script setup>
import { ref, defineProps } from "vue";
import { formatDate, formatMbps, formatBytes } from "../../libs/formatHelpers";
import { getName, getBatteryIcon, getTotalDiskSpace, getFreeDiskSpace } from "../../libs/userDevice";

const props = defineProps(["device"]);
const chip = ref(null);

</script>

<template>
    <v-container class="ma-0" style="width: 685px;">
      <v-row>
        <v-col>
          <div class="text-h5 mx-3" style="font-weight: bold; text-shadow: 1px 1px 1px #000;">{{ getName(device) }}</div>
          <div class="mx-3"><strong>Last data from:</strong> {{ formatDate(device.lastAppearanceAt.toDate()) }}</div>
        </v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="bg-teal-darken-2 ma-3 pa-5 elevation-2 flex-grow-0" style="min-width: 250px; max-width: 250px;">
          <v-row>
            <v-col class="flex-grow-0">
              <v-icon size="x-large" icon="mdi-cellphone-information"></v-icon>
            </v-col>
            <v-col>
              <div style="font-weight: bold;">Manufacturer</div>
              <div class="text-caption">{{ device.model?.manufacturer }}</div>

              <div class="mt-2" style="font-weight: bold;">Type</div>
              <div class="text-caption">{{ device.model?.type }}</div>

              <div class="mt-2" style="font-weight: bold;">OS Version</div>
              <div class="text-caption">{{ device.model?.operatingSystemVersion }}</div>

              <div class="mt-2" style="font-weight: bold;">App Version</div>
              <div class="text-caption">{{ device.package?.version }}+{{ device.package?.buildNumber }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="bg-teal-darken-4 ma-3 pa-5 elevation-2 flex-grow-0" style="min-width: 350px; max-width: 350px;">
          <v-row>
            <v-col class="flex-grow-0">
              <v-icon size="x-large" icon="mdi-memory"></v-icon>
            </v-col>
            <v-col>
              <div style="font-weight: bold;">
                Battery
              </div>
              <div class="text-caption">
                <template v-if="device.battery && device.battery.level >= 0">
                  <v-icon style="margin-top: -3px;" size="x-small" :icon="getBatteryIcon(device)"></v-icon>
                  {{ device.battery.level }}%
                  <div v-if="device.battery.isInBatterySaveMode" style="font-size: 9px;">
                    <strong class="text-orange">Battery save mode</strong> is active
                  </div>
                </template>
                <template v-else>
                  N/A
                </template>
              </div>

              <div class="mt-2" style="font-weight: bold;">Storage</div>
              <div class="text-caption">
                <template v-if="device.storage && device.storage.freeMB >= 0">
                  {{ getFreeDiskSpace(device) }}GB free
                  <span v-if="device.storage.totalMB" style="font-size: 9px;">out of {{ getTotalDiskSpace(device)
                  }}GB</span>
                </template>
                <template v-else>
                  N/A
                </template>
              </div>

              <div class="mt-2" style="font-weight: bold;">Video queue size</div>
              <div class="text-caption">
                <template v-if="device.queue?.size !== undefined">
                  {{ device.queue.size }} videos queued
                </template>
                <template v-else>
                  N/A
                </template>
              </div>

              <div class="mt-2" style="font-weight: bold;">Network</div>
              <div class="text-caption">
                <template v-if="device.network">
                  <v-table class="text-no-wrap bg-transparent" density="compact" style="font-size: 12px;">
                    <thead class="ma-0 pa-0">
                      <tr class="ma-0 pa-0">
                        <td class="px-1 py-0"></td>
                        <td class="px-1 py-0 text-teal-lighten-3"><strong>Last 1 min</strong></td>
                        <td class="px-1 py-0 text-teal-lighten-3"><strong>Last 5 mins</strong></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="pl-0 pr-1 text-teal-lighten-3"><strong>Avg. speed</strong></td>
                        <td class="px-1">{{ formatMbps(device.network.averageSpeedInLastMinuteMbps) }}</td>
                        <td class="px-1">{{ formatMbps(device.network.averageSpeedInLast5minutesMbps) }}</td>
                      </tr>
                      <tr>
                        <td class="pl-0 pr-1 text-teal-lighten-3"><strong>Uploaded</strong></td>
                        <td class="px-1">{{ formatBytes(device.network.uploadedInLastMinuteBytes) }}</td>
                        <td class="px-1">{{ formatBytes(device.network.uploadedInLast5MinutesBytes) }}</td>
                      </tr>
                    </tbody>
                  </v-table>

                </template>
                <template v-else>
                  N/A
                </template>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</template>
