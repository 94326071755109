import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  documentId,
  getDoc,
  writeBatch,
} from "firebase/firestore";

const FIREBASE_BATCH_SIZE = 400;

export async function getTracksForShot(db, shotId, raceId) {
  const shot = await getShot(db, shotId, raceId);
  const startCameraPointTracks = await getStartCameraPointTracks(
    db,
    raceId,
    shot.data().cameraPoint.id
  );
  const trackIds = startCameraPointTracks.map((_) => _.data().track.id);
  const tracks = await getTracks(db, raceId, trackIds);
  return tracks.map((track) => ({
    id: track.id,
    name: track.data().name,
  }));
}

function getShot(db, shotId, raceId) {
  return getDoc(doc(db, "races", raceId, "shots", shotId));
}

async function getTracks(db, raceId, trackIds) {
  const { docs } = await getDocs(
    query(
      collection(db, "races", raceId, "tracks"),
      where(documentId(), "in", trackIds)
    )
  );
  return docs;
}

export async function showStartBoradcastForTracks(
  db,
  shotId,
  raceId,
  trackIds
) {
  const shot = await getShot(db, shotId, raceId);
  const participants = await getParticipantsForTracks(db, raceId, trackIds);
  const { notRecognised, recognised } = getSeparatedParticipants(
    shot,
    participants
  );
  await addParticipantsToShotRecognitions(db, raceId, shotId, notRecognised);
  await showStartBoradcastForParticipants(db, shotId, raceId, recognised);
}

function getSeparatedParticipants(shot, participants) {
  const recognisedParticipants = Object.keys(shot.data().recognitions || {});
  return participants.reduce(
    ({ recognised, notRecognised }, participant) => {
      if (recognisedParticipants.includes(participant.id)) {
        return {
          notRecognised,
          recognised: [...recognised, participant],
        };
      } else {
        return {
          recognised,
          notRecognised: [...notRecognised, participant],
        };
      }
    },
    { recognised: [], notRecognised: [] }
  );
}

async function addParticipantsToShotRecognitions(
  db,
  raceId,
  shotId,
  participants
) {
  const modifications = participants.reduce(
    (recognitions, participant) => ({
      ...recognitions,
      [`recognitions.${participant.id}`]: getRecognition(participant),
    }),
    {}
  );

  await updateDoc(doc(db, "races", raceId, "shots", shotId), modifications);
}

function getRecognition(participant) {
  return {
    bib: participant.data().bib,
    isApproved: true,
    recognizerResults: [
      {
        confidence: 1,
        time: new Date(),
        type: "start",
      },
    ],
  };
}

async function getStartCameraPointTracks(db, raceId, cameraPointId) {
  const { docs } = await getDocs(
    query(
      collection(
        db,
        "races",
        raceId,
        "cameraPoints",
        cameraPointId,
        "cameraPointTracks"
      ),
      where("isStart", "==", true)
    )
  );
  return docs;
}

async function getParticipantsForTracks(db, raceId, trackIds) {
  const { docs } = await getDocs(
    query(
      collection(db, "races", raceId, "participants"),
      where("trackId", "in", trackIds)
    )
  );
  return docs;
}

async function showStartBoradcastForParticipants(
  db,
  shotId,
  raceId,
  participants
) {
  const momentIdsToShow = await getMomentIdsToShow(
    db,
    raceId,
    shotId,
    participants
  );
  await showMoments(db, raceId, momentIdsToShow);
}

async function getMomentIdsToShow(db, raceId, shotId, participants) {
  const shot = await getShot(db, shotId, raceId);
  const { recognitions } = shot.data();
  return participants.reduce(
    (momentIds, participant) =>
      recognitions[participant.id].momentId
        ? [...momentIds, recognitions[participant.id].momentId]
        : momentIds,
    []
  );
}

async function showMoments(db, raceId, momentIds) {
  const momentIdBatches = getBatchedItems(momentIds);
  momentIdBatches.forEach(async (momentIdBatch) => {
    await updateMoments(db, raceId, momentIdBatch, { isVisible: true });
  });
}

export async function hideStartBoradcastForTracks(
  db,
  shotId,
  raceId,
  trackIds
) {
  const shot = await getShot(db, shotId, raceId);
  const participants = await getParticipantsForTracks(db, raceId, trackIds);
  const { recognised } = getSeparatedParticipants(shot, participants);
  const momentIdsToHide = getMomentIdsToHide(shot, recognised);
  await hideMoments(db, raceId, momentIdsToHide);
}

function getMomentIdsToHide(shot, participantsWhoseShotsToHide) {
  const participantIds = participantsWhoseShotsToHide.map((_) => _.id);
  const { recognitions } = shot.data();
  return participantIds.reduce(
    (momentIds, participantId) =>
      recognitions[participantId]
        ? [...momentIds, recognitions[participantId].momentId]
        : momentIds,
    []
  );
}

async function hideMoments(db, raceId, momentIds) {
  const momentIdBatches = getBatchedItems(momentIds);
  momentIdBatches.forEach(async (momentIdBatch) => {
    await updateMoments(db, raceId, momentIdBatch, { isVisible: false });
  });
}

function getBatchedItems(items, batches = []) {
  if (items.length === 0) {
    return batches;
  }
  return getBatchedItems(items.slice(FIREBASE_BATCH_SIZE), [
    ...batches,
    items.slice(0, FIREBASE_BATCH_SIZE),
  ]);
}

async function updateMoments(db, raceId, momentIds, modifications) {
  const batch = writeBatch(db);
  momentIds.forEach((momentId) => {
    batch.update(doc(db, "races", raceId, "moments", momentId), modifications);
  });
  await batch.commit();
}
