import { shotsBaseUrl } from "../config";

export const getVideoUrlForMoment = (moment, shots, selectedRaceId) => {
    const shot = shots.find((shot) => shot.id === moment.shot.id);
    if (!shot) return "";

    const filePath = shot.video.transcodedFile.name;

    if (!filePath) {
      return "";
    }

    if (filePath.includes("https")) {
      return filePath;
    }

    if (filePath) {
      return shotsBaseUrl + selectedRaceId + "/" + filePath;
    }

    return false;
  };