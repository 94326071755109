import { cloneDeep } from "lodash-es";

import { Element, Template } from "../../types";

export function setTracks(originalTemplate: Template): Template {
  const template = cloneDeep(originalTemplate);

  template.elements = (template.elements || []).map((element: Element, index: number) => {
    return { ...element, track: index + 1 };
  });

  return template;
}
