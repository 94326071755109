<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useFirebaseAuth, getCurrentUser } from "vuefire";
import { signInWithRedirect, GoogleAuthProvider } from "firebase/auth";

import SignIn from "./components/SignIn.vue";
import SignOut from "./components/SignOut.vue";

const loaded = ref(false);
const user = ref(null);
const currentPage = ref("Shots");

const auth = useFirebaseAuth();
const route = useRoute();

onMounted(async () => {
  const currentUser = await getCurrentUser();
  if (currentUser) {
    user.value = currentUser;
  }
  loaded.value = true;
});

const signInApp = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithRedirect(auth, provider);
};

const signOutApp = async () => {
  await auth.signOut();
  location.reload();
};

const showUID = async () => {
  if (user === null) {
    return;
  }

  alert(user.value?.uid);
};

const signedIn = computed(() => user.value !== null);

const shouldDisplayAppBar = computed(() => {
  return !route.meta.hideAppBar;
});
</script>

<template>
  <v-layout v-if="loaded === false">
    <v-progress-linear indeterminate></v-progress-linear>
  </v-layout>
  <v-layout v-else>
    <v-app-bar class="app-bar" v-if="shouldDisplayAppBar">
      <template v-slot:prepend>
        <v-img src="/experirace_logo.png" width="48" height="48" @click="showUID()" />
        <h1 class="d-none d-sm-block">ExperiRace</h1>
        <h2 class="d-none d-md-block">Admin</h2>
      </template>
      <template v-slot:append>
        <SignOut v-if="signedIn" @signOut="signOutApp()" />
        <SignIn v-else @signIn="signInApp()" />
      </template>
      <v-tabs v-if="signedIn" v-model="currentPage" centered>
        <v-tab value="Shots" to="/shots">
          <v-icon icon="mdi-video" class="navigation-item-icon"></v-icon>
          <span class="d-none d-sm-inline">Shots</span>
        </v-tab>
        <v-tab value="Runners" to="/runners">
          <v-icon icon="mdi-account-group" class="navigation-item-icon"></v-icon>
          <span class="d-none d-sm-inline">Runners</span>
        </v-tab>
        <v-tab value="Admins" to="/admins">
          <v-icon icon="mdi-account-group" class="navigation-item-icon"></v-icon>
          <span class="d-none d-sm-inline">Admins</span>
        </v-tab>
        <v-tab value="Races" to="/races">
          <v-icon icon="mdi-account-group" class="navigation-item-icon"></v-icon>
          <span class="d-none d-sm-inline">Races</span>
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main v-if="signedIn">
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>
