<script setup>
import { format } from "light-date";
import { defineProps } from "vue";
import { getVideoUrlForMoment } from "../../libs/moments";

const props = defineProps(["participant", "cameraPoints", "selectedRaceId", "shotsBaseUrl", "moments", "shots"]);

function formatDate(date) {
  if (typeof date === "string") date = new Date(date);
  if (!date) return "";
  return format(date, "{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}");
}

function getCameraPointName(cameraPointId) {
  const cameraPoint = props.cameraPoints.find((cameraPoint) => {
    return cameraPoint.id == cameraPointId;
  });

  return cameraPoint ? cameraPoint.name : "";
}

function secondsToMinutesSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);

  return `${minutes}m ${remainingSeconds}s`;
}

function getVideoFromProgressItem(progressItem) {
  const moment = momentByMomentId(progressItem.momentId);
  if (!moment) return "";

  return getVideoUrlForMoment(moment, props.shots, props.selectedRaceId);
}

function momentByMomentId(momentId) {
  return props.moments.find((moment) => moment.id === momentId);
}

function metersToKm(meters) {
    return trimEndZeros((meters / 1000).toFixed(2));
}

function trimEndZeros(str) {
    return str.replace(/\.?0+$/, "");
}
</script>

<template>
    <v-row>
        <v-col>
            <v-table>
                <thead>
                    <tr>
                        <th class="text-left">Progress</th>
                        <th class="text-left">Type</th>
                        <th class="text-left">Time</th>
                        <th class="text-left"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="progressItem in participant.progress" :key="progressItem.distanceM">
                        <td>
                          <strong class="mr-2">
                            {{ metersToKm(progressItem.distanceM) }} km
                          </strong>
                          <v-chip class="mt-n1" size="x-small" color="cyan" v-if="getCameraPointName(progressItem.cameraPointId)">
                            {{ getCameraPointName(progressItem.cameraPointId) }}
                          </v-chip>
                          <v-chip class="ml-1 mt-n1" size="x-small">Lap {{ progressItem.lap }}</v-chip>
                        </td>
                        <td>{{ progressItem.type }}</td>
                        <td>
                            {{ formatDate(progressItem.happenedAt.toDate()) }}
                            <v-chip class="ml-1 mt-n1" size="x-small">{{
                                secondsToMinutesSeconds(progressItem.fromStartTimeSec) }}</v-chip>
                        </td>
                        <td>
                            <v-btn
                              v-if="getVideoFromProgressItem(progressItem)"
                              :href="getVideoFromProgressItem(progressItem)" target="_blank" color="cyan-darken-1"
                                class="mr-3">Download</v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-col>
    </v-row>
</template>
