<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useFirestore, useCollection, useDocument, useCurrentUser } from "vuefire";
import { query, collection, orderBy, updateDoc, doc, serverTimestamp, limit, where, documentId } from "firebase/firestore";

import {
  RECOGNITION_FINISHED_STATUSES,
  RECOGNITION_STATUSES_SELECT,
  RECOGNITION_STATUS_LABELS_SELECT,
} from "../libs/shotHelpers";

import Shot from "./Shot.vue";
import ShotRecognitionsDialog from "./ShotRecognitionsDialog.vue";

const router = useRouter();
const route = useRoute();
const db = useFirestore();

const selectedRaceId = ref(route.params.raceId || null);
const selectedShotId = ref(route.query.shotId || null);

const currentUserUid = useCurrentUser().value.uid;
const currentUser = useDocument(doc(db, "users", currentUserUid));

const showAllShots = ref(selectedShotId.value ? true : false);
const showNewShotsFirst = ref(false);
const showStartCamera = ref(selectedShotId.value ? true : false);
const showAIInProgress = ref(selectedShotId.value ? true : false);
const noLimitOnShots = ref(false);

const numberOfShotsIncrement = 100;
const numberOfShots = ref(100);

watch(selectedRaceId, () => {
  selectedParticipant.value = "All";
  router.push({ name: `shots`, params: { raceId: selectedRaceId.value } });
});

const races = useCollection(query(collection(db, "races"), orderBy("startAt", "desc")));

const racesForSelection = computed(() => {
  return [
    ...races.value.map((race) => {
      return {
        title: race.name,
        id: race.id,
      };
    }),
  ];
});

const selectedRace = computed(() => {
  if (selectedRaceId.value && races.value) {
    return races.value.reduce((i, race) => {
      if (race.id == selectedRaceId.value) return race;
      return i;
    }, {});
  }
});

const raceConfigQuery = computed(() => {
  if (!selectedRaceId.value) {
    return;
  }

  return doc(db, `races/${selectedRaceId.value}/configs`, "default");
});

const raceConfig = useDocument(raceConfigQuery);

const selectedParticipant = ref("All");
const selectedRecognitionStatuses = ref(Object.keys(RECOGNITION_STATUSES_SELECT));

const availableParticipantsQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "participants"), orderBy("bib", "asc"));
  }
});

const availableParticipants = useCollection(availableParticipantsQuery);

const availableParticipantsWithTitle = computed(() => {
  return availableParticipants.value.map((participant) => {
    const track = availableTracks.value.find((track) => track.id == participant.trackId);
    const runnerTrackName = track ? ` (${track.name})` : "";
    const isRegisteredFlag = participant.isRegistered ? " [REG]" : "";

    return {
      title: `${participant.bib} - ${participant.name}${runnerTrackName}${isRegisteredFlag}`,
      name: participant.name,
      bib: participant.bib,
      id: participant.id,
    };
  });
});

const availableTracksQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "tracks"));
  }
});

const availableTracks = useCollection(availableTracksQuery);

const participantsForSelection = computed(() => {
  return [
    {
      title: "All",
      id: "All",
    },
    ...availableParticipantsWithTitle.value,
  ];
});

const selectedCameraPoint = ref("All");

const availableCameraPointsQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "cameraPoints"), orderBy("rank", "asc"));
  }
});

const availableCameraPoints = useCollection(availableCameraPointsQuery);

const cameraPointsForSelection = computed(() => {
  return [
    {
      title: "All",
      id: "All",
    },
    ...availableCameraPoints.value.map((cameraPoint) => {
      return {
        title: `${cameraPoint.name}`,
        id: cameraPoint.id,
      };
    }),
  ];
});

const selectedRecognitionStatusesToFilter = computed(() => {
  return selectedRecognitionStatuses.value.reduce((statusesToFilter, status) => {
    if (status === RECOGNITION_STATUSES_SELECT.RECOGNITION_FINISHED) {
      return statusesToFilter.concat(RECOGNITION_FINISHED_STATUSES);
    }

    return statusesToFilter.concat(status);
  }, []);
});

const shotsQuery = computed(() => {
  if (!selectedRaceId.value) return;

  if (selectedShotId.value) {
    return query(collection(db, "races", selectedRaceId.value, "shots"), where(documentId(), "==", selectedShotId.value));
  }

  const constraints = [orderBy("video.timing.startAt", showNewShotsFirst.value ? "desc" : "asc")];

  if (selectedRecognitionStatuses.value.length) {
    constraints.push(where("recognitionStatus", "in", selectedRecognitionStatusesToFilter.value));
  }

  if (selectedCameraPoint.value != "All") {
    constraints.push(where("cameraPoint.id", "==", selectedCameraPoint.value));
  }

  if (noLimitOnShots.value || selectedParticipant.value != "All" || selectedCameraPoint.value != "All") {
    constraints.push(limit(10000));
  } else {
    constraints.push(limit(numberOfShots.value));
  }

  return query(collection(db, "races", selectedRaceId.value, "shots"), ...constraints);
});

const startCameraPointId = computed(() => {
  if (!selectedRace.value && availableCameraPoints.value) return;

  let startCameraPoint = availableCameraPoints.value.find((cameraPoint) => cameraPoint.isStart);

  if (!startCameraPoint) {
    startCameraPoint = availableCameraPoints.value.find((cameraPoint) => cameraPoint.rank == 0);
  }

  if (!startCameraPoint) {
    startCameraPoint = availableCameraPoints.value.find((cameraPoint) => cameraPoint.name.toLowerCase() === "start");
  }

  return startCameraPoint?.id;
});

const shots = useCollection(shotsQuery);

const selectedParticipantIsInShot = (shot) => {
  if (selectedParticipant.value == "All") return true;
  if (!shot.recognitions) return false;
  return (
    Boolean(shot.recognitions[selectedParticipant.value]) &&
    shot.recognitions[selectedParticipant.value].isApproved !== false
  );
};

const shouldShowShot = (shot) => {
  if (!selectedParticipantIsInShot(shot)) {
    return false;
  }

  if (!showAllShots.value && isClaimed(shot) && !isClaimedByMe(shot)) {
    return false;
  }

  if (!showStartCamera.value && shot.cameraPoint.id === startCameraPointId.value) {
    return false;
  }

  if (!showAIInProgress.value && shot.recognitionStatus === "PENDING") {
    return false;
  }
  return true;
};

const recognitionStatusesForSelection = computed(() => {
  return [
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.PENDING,
      id: RECOGNITION_STATUSES_SELECT.PENDING,
    },
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.RECOGNITION_FINISHED,
      id: RECOGNITION_STATUSES_SELECT.RECOGNITION_FINISHED,
    },
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.AUTO_DONE_ALL_DECISIVE,
      id: RECOGNITION_STATUSES_SELECT.AUTO_DONE_ALL_DECISIVE,
    },
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.DONE,
      id: RECOGNITION_STATUSES_SELECT.DONE,
    },
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.MISSING_RUNNERS,
      id: RECOGNITION_STATUSES_SELECT.MISSING_RUNNERS,
    },
    {
      title: RECOGNITION_STATUS_LABELS_SELECT.UNKNOWN,
      id: RECOGNITION_STATUSES_SELECT.UNKNOWN,
    },
  ];
});

const addMoreShots = () => {
  numberOfShots.value += numberOfShotsIncrement;
};

const isClaimed = (shot) => {
  return Boolean(shot.backend?.claim?.owner?.uid);
};

const isClaimedByMe = (shot) => {
  return shot.backend?.claim?.owner?.uid === currentUserUid;
};

const claimedBy = (shot) => {
  return shot.backend?.claim?.owner?.name;
};

const claim = (shot) => {
  const shotRef = doc(db, "races", selectedRaceId.value, "shots", shot.id);
  updateDoc(shotRef, {
    "backend.claim": {
      time: serverTimestamp(),
      owner: {
        uid: currentUserUid,
        name: currentUser.value.name,
      },
    },
    "lifecycle.updatedAt": serverTimestamp(),
  });
};

const release = (shot) => {
  const shotRef = doc(db, "races", selectedRaceId.value, "shots", shot.id);
  updateDoc(shotRef, {
    "backend.claim": {},
    "lifecycle.updatedAt": serverTimestamp(),
  });
};

const isSelectedRace = () => {
  return selectedRaceId.value;
};

const openRecognitionDialogShotId = ref(null);
const openRecognitionDialog = (shotId) => (openRecognitionDialogShotId.value = shotId);
const onRecognitionDialogClose = () => (openRecognitionDialogShotId.value = null);
const openedRecognitionStatus = computed(() => {
  if (!openRecognitionDialogShotId.value) return;
  const shot = shots.value.find((shot) => shot.id === openRecognitionDialogShotId.value);
  return shot.recognitionStatus;
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="controls-container">
        <div class="controls-container__row">
          <v-select
            class="float-left control"
            label="Select race"
            density="comfortable"
            :items="racesForSelection"
            v-model="selectedRaceId"
            item-title="title"
            item-value="id"
          ></v-select>
          <v-select
            v-if="isSelectedRace()"
            class="float-left control"
            label="Select runner"
            density="comfortable"
            :items="participantsForSelection"
            v-model="selectedParticipant"
            item-title="title"
            item-value="id"
          ></v-select>
          <v-select
            v-if="isSelectedRace()"
            class="float-left control"
            label="Select camera"
            density="comfortable"
            :items="cameraPointsForSelection"
            v-model="selectedCameraPoint"
            item-title="title"
            item-value="id"
          ></v-select>
          <v-select
            v-if="isSelectedRace()"
            class="float-left control long-select"
            label="Select status"
            density="comfortable"
            multiple
            :items="recognitionStatusesForSelection"
            v-model="selectedRecognitionStatuses"
            item-title="title"
            item-value="id"
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="selectedRecognitionStatuses.length === 1 && index === 0">
                {{ item.title }}
              </div>
              <div v-else-if="selectedRecognitionStatuses.length > 1 && index === 0">
                {{ selectedRecognitionStatuses.length }} selected
              </div>
            </template>
          </v-select>
        </div>
        <div class="controls-container__row">
          <v-switch
            v-if="isSelectedRace()"
            class="float-left control"
            v-model="showAllShots"
            label="Show claimed by others"
          >
          </v-switch>
          <v-switch
            v-if="isSelectedRace()"
            class="float-left control"
            v-model="showNewShotsFirst"
            label="New shots first"
          >
          </v-switch>
          <v-switch
            v-if="isSelectedRace()"
            class="float-left control"
            v-model="showStartCamera"
            label="Show start camera"
          >
          </v-switch>
          <v-switch
            v-if="isSelectedRace()"
            class="float-left control"
            v-model="showAIInProgress"
            label="Show AI in Progress"
          >
          </v-switch>
          <v-switch
            v-if="isSelectedRace()"
            class="float-left control"
            v-model="noLimitOnShots"
            label="Load all shots"
          >
          </v-switch>
        </div>
      </v-col>
    </v-row>

    <v-row v-for="shot in shots" :key="shot.id">
      <v-col v-if="shouldShowShot(shot)">
        <Shot
          :shot="shot"
          :raceConfig="raceConfig"
          :availableParticipants="availableParticipants"
          :race="selectedRace"
          :isClaimed="isClaimed(shot)"
          :isClaimedByMe="isClaimedByMe(shot)"
          :claimedBy="claimedBy(shot)"
          @claim="claim(shot)"
          @release="release(shot)"
          @openRecognitionDialog="(shotId) => openRecognitionDialog(shotId)"
        />
      </v-col>
    </v-row>

    <v-row v-if="isSelectedRace() && numberOfShots">
      <v-col></v-col>
      <v-col class="d-flex justify-center">
        <v-btn @click="addMoreShots()" append-icon="mdi-camera-burst">
          Load {{ numberOfShotsIncrement }} more shots
        </v-btn>
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col>
        <ShotRecognitionsDialog
          :shotId="openRecognitionDialogShotId"
          :shotRecognitionStatus="openedRecognitionStatus"
          :race="selectedRace"
          :availableParticipants="availableParticipantsWithTitle"
          @close="onRecognitionDialogClose()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
