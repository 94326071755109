<script setup>
import { shotsBaseUrl } from "../../config";
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { defineProps, ref, computed } from "vue";
import { useFirestore } from "vuefire";

const db = useFirestore();

const props = defineProps(["moment", "shot", "participant", "race"]);
const dialog = ref(false);
const slider = ref([0, 0]);
const video = ref(null);
const isLoading = ref(false);
let lastSliderValue = null;


const isTrimTooShort = computed(() => {
  return slider.value[1] - slider.value[0] < 4000;
});

const isTrimSet = computed(() => {
  return props.moment.shot.video.timing.participantAppearedAtSec !== undefined;
});

const videoLengthMs = ref(0);

function dialogChanged(isDialogShown) {
  if (!isDialogShown) return;

  isLoading.value = false;
  const trimStart = props.moment?.shot?.video?.timing?.participantAppearedAtSec * 1000 || 0;

  const trimEnd = (props.moment?.shot?.video?.timing?.participantAppearedAtSec + props.moment?.shot?.video?.timing?.participantAppearedForSec) * 1000 || videoLengthMs.value;

  slider.value = [trimStart, trimEnd];
  lastSliderValue = slider.value;
}

const videoUrl = computed(() => {
  const shot = props.shot;

  if (shot && shot.video?.transcodedFile?.name) {
    if (shot.video.transcodedFile.name.includes("https://")) {
      return shot.video.transcodedFile.name;
    }

    return (
      shotsBaseUrl + props.race.id + "/" + shot.video.transcodedFile.name
    );
  } else if (shot && shot.video?.file?.name) {
    if (shot.video.file.name.includes("https://")) {
      return shot.video.file.name;
    }

    return shotsBaseUrl + props.race.id + "/" + shot.video.file.name;
  }

  return false;
});

function msToSeconds(ms) {
  return ms / 1000;
}

function sliderChanged(newValue) {
  const isStartChanged = newValue[0] !== lastSliderValue[0];
  const isEndChanged = newValue[1] !== lastSliderValue[1];

  if (isStartChanged) {
    seekVideoTo(newValue[0]);
  } else if (isEndChanged) {
    seekVideoTo(newValue[1]);
  }

  lastSliderValue = newValue;
}

function seekVideoTo(ms) {
  video.value.currentTime = ms / 1000;
}

async function saveAppearancesToMoment(isActive) {
  isLoading.value = true;

  const trimStartAsSec = slider.value[0] / 1000;
  const durationSec = (slider.value[1] - slider.value[0]) / 1000;

  const momentDocRef = doc(db, `races/${props.race.id}/moments`, props.moment.id);
  await updateDoc(momentDocRef, {
    "shot.video.timing.participantAppearedAtSec": trimStartAsSec,
    "shot.video.timing.participantAppearedForSec": durationSec,
    "lifecycle.updated": serverTimestamp(),
  });

  close();
}

function close() {
  dialog.value = false;
}

function setVideoDuration() {
  videoLengthMs.value = video.value.duration * 1000;
}

</script>

<template>
  <v-btn v-if="videoUrl" prepend-icon="mdi-scissors-cutting" size="small" :color="isTrimSet ? 'green' : 'cyan-darken-1'">
    Trim
    <v-dialog activator="parent" width="500" v-model="dialog" @update:modelValue="dialogChanged">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title>
            Trim Video
          </v-card-title>
          <v-card-text class="pa-5 pb-0 d-flex align-center flex-column" v-if="!isLoading">
            <div>
              <video
                ref="video"
                style="width: 300px;"
                class="rounded elevation-3"
                controls
                preload="auto"
                poster="/placeholder.jpg"
                @loadedmetadata="setVideoDuration"
              >
                <source type="video/mp4" :src="videoUrl" />
              </video>
            </div>
            <div class="mt-3" style="width: 270px;">
              <v-range-slider
                v-if="videoLengthMs > 0"
                class="w-100 pa-0 ma-0"
                v-model="slider"
                strict
                track-size="8"
                step="1"
                thumb-label="always"
                thumb-size="20"
                min="0"
                :max="videoLengthMs"
                @update:model-value="sliderChanged"
              >
                <template v-slot:thumb-label="{ modelValue }">
                  <div class="text-no-wrap">
                    <strong>{{ msToSeconds(modelValue) }} s</strong>
                  </div>
                </template>
              </v-range-slider>
            </div>

          </v-card-text>
          <v-card-text v-if="isLoading">
            <v-progress-linear class="mt-3 mb-8" indeterminate color="primary"></v-progress-linear>
          </v-card-text>
          <v-card-actions class="mt-0 pt-0" v-if="!isLoading">
            <v-spacer></v-spacer>
            <div v-if="isTrimTooShort">
              <v-alert class="py-1 pl-2 pr-4" size="small" dense="compact" type="error" variant="flat" icon="mdi-alert" style="font-size: 90%;">
                <strong>Trim duration is too short</strong>
              </v-alert>
            </div>
            <v-btn color="blue" text @click="saveAppearancesToMoment">Save</v-btn>
            <v-btn text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-btn>
</template>
