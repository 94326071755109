<script setup>
import { ref, defineProps, onUnmounted, onMounted } from "vue";
import { getName, getBatteryIcon, getFreeDiskSpace, getChargingColor, getDiskColor, getQueueColor } from "../../libs/userDevice";
import UserDeviceData from "./userDeviceData.vue";

const props = defineProps(["device"]);
const chip = ref(null);

const timeAt15MinutesAgo = ref(getTimeAt15MinutesAgo());

let unsubscribeTime;

onMounted(() => {
  unsubscribeTime = setInterval(() => {
    timeAt15MinutesAgo.value = getTimeAt15MinutesAgo();
  }, 2000);
});

onUnmounted(() => {
  clearInterval(unsubscribeTime);
});

function isOnline(device) {
  return device.lastAppearanceAt?.toDate() > timeAt15MinutesAgo.value;
}

function getTimeAt15MinutesAgo() {
  return new Date(new Date().getTime() - 15 * 60 * 1000);
};
</script>

<template>
  <v-chip ref="chip" size="large" class="elevation-3 ma-2" style="cursor: pointer;" :style="{ opacity: isOnline(device) ? 1 : 0.5 }">
    <v-icon class="ml-n1 mr-n1" icon="mdi-apple" v-if="device.model?.manufacturer === 'Apple'"></v-icon>
    <v-icon icon="mdi-android" v-else></v-icon>

    <template v-if="isOnline(device)">
      <span class="text-caption ml-2 text-truncate" style="max-width: 120px;">
        <strong>{{ getName(device) }}</strong>
      </span>

      <v-chip size="x-small" class="ml-2 elevation-1" v-if="device.battery?.level !== undefined">
        <v-icon size="small" :color="getChargingColor(device)" :icon="getBatteryIcon(device)"></v-icon>
        <span style="font-size: 11px; font-weight: bold; padding-top: 1px; padding-left: 3px;">{{ device.battery.level
        }}%</span>
      </v-chip>

      <v-chip size="x-small" class="ml-1 elevation-1" v-if="device.storage?.freeMB !== undefined">
        <v-icon size="small" :color="getDiskColor(device)" icon="mdi-server"></v-icon>
        <span style="font-size: 11px; font-weight: bold; padding-top: 1px; padding-left: 3px;">{{ getFreeDiskSpace(device)
        }}GB</span>
      </v-chip>

      <v-chip size="x-small" class="ml-1 elevation-1" v-if="device.queue?.size >= 0">
        <v-icon size="small" :color="getQueueColor(device)" icon="mdi-cloud-upload" style="margin-top: 1px;"></v-icon>
        <span style="font-size: 11px; font-weight: bold; padding-top: 1px; padding-left: 3px;">{{ device.queue.size
        }}</span>
      </v-chip>
    </template>
  </v-chip>

  <v-tooltip location="top" :activator="chip">
    <user-device-data :device="device"></user-device-data>
  </v-tooltip>
</template>
