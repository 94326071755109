import { ref } from 'vue';

export class RecognitionProgress {

    #shotStart;
    #recognitionTimeoutSeconds;
    #getStatusClassForRecognitionType;
    #isAiRecognitionDone;
    #isRecognitionInProgress = ref();
    #recognitionProgress = ref();
    #recognitionProgressInterval;

    constructor({ shotStart, recognitionTimeoutSeconds, getStatusClassForRecognitionType, isAiRecognitionDone }) {
        this.#shotStart = shotStart;
        this.#recognitionTimeoutSeconds = (typeof recognitionTimeoutSeconds == "number") ? recognitionTimeoutSeconds : 60 * 2;
        this.#getStatusClassForRecognitionType = getStatusClassForRecognitionType;
        this.#isAiRecognitionDone = isAiRecognitionDone;
    }

    startProgress() {
        if (this.#recognitionProgressInterval) clearInterval(this.#recognitionProgressInterval);
        if (this.isRecognitionDone()) return;

        const currentTime = new Date();
        const secondsBetweenStartAndCurrent = (currentTime - this.#shotStart) / 1000;
        const recognitionTimeStep = 100 / this.#recognitionTimeoutSeconds;

        this.#isRecognitionInProgress.value = secondsBetweenStartAndCurrent < this.#recognitionTimeoutSeconds;
        this.#recognitionProgress.value = secondsBetweenStartAndCurrent >= this.#recognitionTimeoutSeconds ?
            100 : secondsBetweenStartAndCurrent * recognitionTimeStep;

        this.#recognitionProgressInterval = this.startInterval(recognitionTimeStep);
    }

    startInterval(recognitionTimeStep) {
        const recognitionProgressInterval = setInterval(() => {
            if (this.#isRecognitionInProgress.value) {
                if (this.isRecognitionDone()) {
                    this.#recognitionProgress.value = 100;
                    this.#isRecognitionInProgress.value = false;
                    clearInterval(recognitionProgressInterval);
                } else {
                    this.#recognitionProgress.value += recognitionTimeStep;
                    if (this.#recognitionProgress.value >= 100) {
                        this.#isRecognitionInProgress.value = false;
                        clearInterval(recognitionProgressInterval);
                    }
                }
            }
        }, 1000);

        return recognitionProgressInterval;
    }

    isRecognitionDone() {
        return this.#isAiRecognitionDone() || this.#getStatusClassForRecognitionType('bib') === 'finished';
    }

    getRefs() {
        return {
            isRecognitionInProgress: this.#isRecognitionInProgress,
            recognitionProgress: this.#recognitionProgress
        }
    }

    static init({ shotStart, recognitionTimeoutSeconds, getStatusClassForRecognitionType, isAiRecognitionDone }) {
        const recognitionProgress = new RecognitionProgress({ shotStart, recognitionTimeoutSeconds, getStatusClassForRecognitionType, isAiRecognitionDone });
        recognitionProgress.startProgress();
        return recognitionProgress.getRefs();
    }

}

