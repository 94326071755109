<script setup>
import { computed } from "vue";
import UserDevice from "./userDevice.vue";

const props = defineProps(["devices"]);

const devicesInOrderOfAppearance = computed(() => {
  return [ ...props.devices ].sort((a, b) => {
    if (a.lastAppearanceAt.toDate() > b.lastAppearanceAt.toDate()) return -1;
    if (a.lastAppearanceAt.toDate() < b.lastAppearanceAt.toDate()) return 1;
    return 0;
  });
});
</script>

<template>
    <user-device :device="device" v-for="device in devicesInOrderOfAppearance" :key="device.deviceId">
    </user-device>
</template>
