import { Element, Template, TemplateTag } from "../../types";
import { cloneDeep } from "lodash-es";

export const OVERLAP_BETWEEN_ELEMENTS_IN_SECONDS = 0.2;

export function updateElementTimings(originalTemplate: Template): Template {
  const template = cloneDeep(originalTemplate);

  const elements: Element[] = [];
  let lastElementTime = 0;
  let lastElementDuration = 0;

  for (const element of template.elements || []) {
    if (!element.name?.includes(TemplateTag.SKIP_ROOT_TIMING)) {
      element.time = Math.max(lastElementTime + lastElementDuration - OVERLAP_BETWEEN_ELEMENTS_IN_SECONDS, 0);

      lastElementTime = element.time;
      lastElementDuration = element.duration || 0;
    }

    elements.push(element);
  }

  return { ...template, elements };
}
