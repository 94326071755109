import { createApp } from "vue";
import { setupSentry } from "./plugins/sentry.js";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VueFire, VueFireAuth, getCurrentUser, useFirestore } from "vuefire";
import { initializeApp } from "firebase/app";
import { createRouter, createWebHashHistory } from "vue-router";
import { query, doc, getDocFromServer } from "firebase/firestore";

import { firebaseConfig } from "./config.js";

import SignUp from "./components/SignUp.vue";
import Admins from "./components/Admins.vue";
import Shots from "./components/Shots.vue";
import Runners from "./components/Runners.vue";
import Races from "./components/races/Races.vue";
import Timer from "./components/Timer.vue";

const routes = [
  { name: "", path: "/", redirect: { path: "/signup" } },
  {
    name: "signup",
    path: "/signup",
    component: SignUp,
    meta: {
      requiresAuthenticatedUser: false,
    },
  },
  {
    name: "admins",
    path: "/admins",
    component: Admins,
    meta: {
      requiresAuthenticatedUser: true,
      requiredRole: "admin",
    },
  },
  {
    name: "shots",
    path: "/shots/:raceId?",
    component: Shots,
    meta: {
      requiresAuthenticatedUser: true,
      requiredRole: "shot_manager",
    },
  },
  {
    name: "runners",
    path: "/runners/:raceId?",
    component: Runners,
    meta: {
      requiresAuthenticatedUser: true,
      requiredRole: "runner_manager",
    },
  },
  {
    name: "races",
    path: "/races",
    component: Races,
    meta: {
      requiresAuthenticatedUser: true,
      requiredRole: "admin",
    },
  },
  {
    name: "timer",
    path: "/timers",
    component: Timer,
    meta: {
      requiresAuthenticatedUser: true,
      requiredRole: "admin",
      hideAppBar: true,
    },
  },
];

import App from "./App.vue";

import "./style.css";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

const experiRaceTheme = {
  dark: true,
  colors: {
    background: "#1b1a19",
    surface: "#1b1a19",
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "experiRaceTheme",
    themes: {
      experiRaceTheme,
    },
  },
});

const firebaseApp = initializeApp(firebaseConfig);

const db = useFirestore();

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const getUserRolesFromDbByUid = async (uid) => {
  const userFromDb = await getDocFromServer(query(doc(db, "users", uid)));
  if (!userFromDb.data()) return [];

  return userFromDb.data().roles || [];
};

router.beforeEach(async (to, from) => {
  if (!to.meta.requiresAuthenticatedUser) return;

  const userFromFirebaseAuth = await getCurrentUser();

  if (userFromFirebaseAuth) {
    const rolesOfUser = await getUserRolesFromDbByUid(userFromFirebaseAuth.uid);

    if (!rolesOfUser.includes(to.meta.requiredRole)) {
      return { name: "signup" };
    }
  } else {
    return { name: "signup" };
  }
});

const app = createApp(App)
  .use(VueFire, {
    firebaseApp,
    modules: [VueFireAuth()],
  })
  .use(vuetify)
  .use(router);

  setupSentry(app, router);

  app.mount("#app");
