import { format } from "light-date";

export const formatPercentage = (percentage) => {
  return `${Math.round(percentage * 100)}%`;
};

export const formatDistance = (distanceM) => {
  if (distanceM < 1000) return `${Math.round(distanceM)} m`;
  else return `${Math.round(distanceM / 100) / 10} km`;
};

export const formatDate = (date) => {
  try {
    if (typeof date === "string") date = new Date(date);
    return format(date, "{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}");
  } catch {
    console.error(`Cannot format date: ${date}`);
    return "";
  }
};

export const formatMbps = (mbps) => {
  return Math.round((mbps / 8) * 100) / 100 + " MB/s";
};

export const formatBytes = (bytes) => {
  if (bytes > 1024 * 1024 * 1024) return `${bytesToGB(bytes)} GB`;
  return `${bytesToMB(bytes)} MB`;
};

const bytesToMB = (bytes) => {
  return Math.round((bytes / 1024 / 1024) * 10) / 10;
};

const bytesToGB = (bytes) => {
  return Math.round((bytes / 1024 / 1024 / 1024) * 100) / 100;
};
