<script setup>
import { ref } from "vue";

import { useFirestore } from "vuefire";
import { doc, setDoc, deleteField } from "firebase/firestore";

const RACE_RESULT = "race-result";

const props = defineProps(["raceId", "raceConfig", "tracks"]);

const open = ref(false);

const db = useFirestore();

const updateIntegrationIdOfTrack = async (raceId, trackId, integrationId) => {
  const id = integrationId ? integrationId : deleteField();
  await setDoc(
    doc(db, "races", raceId, "tracks", trackId),
    {
      integrations: { "race-result": { id } },
    },
    { merge: true }
  );
};

const updateIntegrationEnabled = async (raceId, isEnabled) => {
  await setDoc(
    doc(db, "races", raceId, "configs", "default"),
    {
      integrations: { "race-result": { isEnabled } },
    },
    { merge: true }
  );
};

const updateParticipantUpdateUrl = async (raceId, url) => {
  const participantListUrl = url ? url : deleteField();
  await setDoc(
    doc(db, "races", raceId, "configs", "default"),
    {
      integrations: { "race-result": { participantListUrl } },
    },
    { merge: true }
  );
};
</script>

<template>
  <v-container class="ma-0 pa-0">
    <v-row
      ><v-col><strong>Race Result Integration</strong></v-col></v-row
    >

    <v-row>
      <v-col v-if="props.raceConfig">
        <v-select
          class="float-left control"
          style="width: 250px !important; max-width: 250px !important"
          label="Enabled"
          density="comfortable"
          hide-details="true"
          :items="[
            { title: 'true', value: true },
            { title: 'false', value: false },
          ]"
          :model-value="(props.raceConfig.integrations || {})[RACE_RESULT]?.isEnabled || false"
          @update:model-value="
            async (isEnabled) => {
              await updateIntegrationEnabled(props.raceId, isEnabled);
            }
          "
          item-title="title"
          item-value="value"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="props.raceConfig">
        <v-text-field
          class="pt-3"
          label="Participant update URL"
          :model-value="(props.raceConfig.integrations || {})[RACE_RESULT]?.participantListUrl"
          @update:model-value="
            async (url) => {
              await updateParticipantUpdateUrl(props.raceId, url);
            }
          "
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="props.tracks">
        <v-btn variant="tonal" @click="open = !open">
          Track external IDs
          <v-icon v-if="!open" class="ml-1" size="8">mdi-triangle-down</v-icon>
          <v-icon v-else class="ml-1" size="8">mdi-triangle</v-icon>
        </v-btn>

        <template v-if="open">
          <v-text-field
            class="pt-3"
            v-for="track in props.tracks"
            :key="track.id"
            :label="track.name"
            :model-value="(track.integrations || {})[RACE_RESULT]?.id"
            @update:model-value="
              async (integrationId) => {
                await updateIntegrationIdOfTrack(props.raceId, track.id, integrationId);
              }
            "
            hide-details
          ></v-text-field>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
