<script setup>
import { computed, ref, watch } from "vue";
import UserDeviceData from "./userDeviceData.vue";

import {
  useFirestore,
} from "vuefire";

import {
  query,
  where,
  collectionGroup,
  getDocs,
} from "firebase/firestore";

const db = useFirestore();

const props = defineProps(["owner", "isActive"]);

const device = ref(null);
const isLoading = ref(false);
const isDeviceFound = ref(null);

watch(props.isActive, () => {
  if (props.isActive.value && !isLoading.value) {
    fetchDevice();
  }
}, { immediate: true });

const fetchDevice = async () => {
  isLoading.value = true;

  const devicesQuery = query(
    collectionGroup(db, 'devices'),
    where('deviceId', '==', props.owner.device.deviceId),
  );

  const querySnapshot = await getDocs(devicesQuery);
  const devices = querySnapshot.docs.map((doc) => doc.data());

  const ownerDevices = devices.filter((device) => device.owner.uid === props.owner.uid);

  if (ownerDevices.length > 0) {
    device.value = ownerDevices[0];
    isDeviceFound.value = true;
  } else {
    isDeviceFound.value = false;
  }

  isLoading.value = false;
};
</script>

<template>
  <div v-if="isDeviceFound === false">
    Device is not found
  </div>
  <user-device-data v-else-if="device" :device="device"></user-device-data>
  <v-progress-circular size="small" class="my-2 mx-0" v-else indeterminate></v-progress-circular>
</template>
