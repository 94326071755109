import type { Race, Participant, Moment } from "../../common/domain/types";

export type Element = {
  id: string;
  type: ElementType;
  name?: string;
  text?: string;
  track?: number;
  duration?: number;
  time?: number;
  trim_start?: number;
  elements?: Element[];
  source?: string;
};

export enum ElementType {
  AUDIO = "audio",
  COMPOSITION = "composition",
  VIDEO = "video",
  TEXT = "text",
}

export type Template = {
  duration?: number;
  elements?: Element[];
};

export type TimingProperties = "time" | "duration";

export type ChangeSourceInCompositionOptions = {
  composition: Element;
  videoSource: string;
  videoDuration: number;
  videoTrimStart: number;
  distanceM?: number;
  raceTimeSec?: number;
  happenedAt?: Date;
  timeZone?: string;
  shotsUrl?: string;
};

export type RenderTemplateOptions = {
  template: Template;
  race: Race;
  participant: Participant;
  moments: Moment[];
  shotsUrl: string;
};

export enum TemplateTag {
  RACE_NAME = "[RACE_NAME]",
  PARTICIPANT_NAME = "[PARTICIPANT_NAME]",
  PARTICIPANT_BIB = "[PARTICIPANT_BIB]",
  FINISH_TIME = "[FINISH_TIME]",

  START_ELEMENT = "[START_ELEMENT]",
  FINISH_ELEMENT = "[FINISH_ELEMENT]",
  PROGRESS_ELEMENT = "[PROGRESS_ELEMENT]",

  VIDEO = "[VIDEO]",
  CHANGE_TIME = "[VIDEO_CHANGE_TIME]",
  CHANGE_DURATION = "[VIDEO_CHANGE_DURATION]",
  DISTANCE = "[VIDEO_DISTANCE]",
  RACE_TIME = "[VIDEO_RACE_TIME]",
  HAPPENED_AT = "[VIDEO_HAPPENED_AT]",

  SKIP_ROOT_TIMING = "[SKIP_ROOT_TIMING]",
}

