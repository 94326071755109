<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { format } from "light-date";

import { useFirestore, useCollection, useFirebaseStorage, useStorageFile } from "vuefire";
import { query, collection, orderBy, updateDoc, doc, serverTimestamp, where } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { getVideoUrlForMoment } from "../libs/moments";

import { shotsBaseUrl, postRaceVideosBucketUrl, postRaceVideosBaseUrl } from "../config";

import RenderPostRaceVideo from "./runners/RenderPostRaceVideo.vue";
import ProgressTable from "./runners/ProgressTable.vue";
import VideoTrimmer from "./runners/VideoTrimmer.vue";

const router = useRouter();
const route = useRoute();
const db = useFirestore();

const siteUrl = __SITE_URL__;

const selectedRaceId = ref(route.params.raceId || null);

const postRaceVideoFileToUpload = ref(null);
const uploadIsInProgress = ref(false);
const uploadProgressPercentage = ref(0);

const storage = useFirebaseStorage();

const savePostRaceVideoFileName = async (fileName) => {
  const participantRef = doc(db, "races", selectedRaceId.value, "participants", selectedParticipant.value.id);

  await updateDoc(participantRef, {
    "postRace.video.file.name": fileName,
    "lifecycle.updatedAt": serverTimestamp(),
  });
};

const uploadFile = async (file) => {
  uploadProgressPercentage.value = 0;

  const raceId = selectedRaceId.value;
  const participantId = selectedParticipant.value.id;

  const datePrefix = format(new Date(), "{yyyy}{MM}{dd}{HH}{mm}{ss}");
  const uploadedFileName = `${datePrefix}_${raceId}_${participantId}_${file.name}`;

  const fileRef = storageRef(storage, `${postRaceVideosBucketUrl}/${uploadedFileName}`);

  const { uploadProgress, updateMetadata, upload } = useStorageFile(fileRef);

  watch(uploadProgress, (progress) => {
    uploadProgressPercentage.value = Math.round(uploadProgress.value * 100);

    if (progress == 1) {
      uploadIsInProgress.value = false;
      postRaceVideoFileToUpload.value = null;
    }
  });

  await upload(file);
  await updateMetadata({
    contentDisposition: "attachment",
  });
  await savePostRaceVideoFileName(uploadedFileName);
};

const uploadSelectedPostRaceVideo = async () => {
  if (postRaceVideoFileToUpload.value) {
    uploadIsInProgress.value = true;
    const firstFile = postRaceVideoFileToUpload.value;
    await uploadFile(firstFile);
  }
};

const getUrlForPostRaceVideo = () => {
  if (selectedParticipant.value?.postRace?.video?.file?.name) {
    return `${postRaceVideosBaseUrl}${selectedParticipant.value.postRace.video.file.name}`;
  }

  return false;
};

const hasPostRaceVideo = computed(() => {
  return !!selectedParticipant.value?.postRace?.video?.file?.name;
});

watch(selectedRaceId, () => {
  router.push({
    name: `runners`,
    params: { raceId: selectedRaceId.value ? selectedRaceId.value : "" },
  });
  selectedTrackId.value = null;
  selectedParticipantId.value = "All";
});

const races = useCollection(query(collection(db, "races"), orderBy("startAt", "desc")));

const racesForSelection = computed(() => {
  return [
    ...races.value.map((race) => {
      return {
        title: race.name,
        id: race.id,
      };
    }),
  ];
});

const availableTracksQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "tracks"));
  }
});

const availableTracks = useCollection(availableTracksQuery);

const tracksForSelection = computed(() => {
  const tracks = availableTracks.value.map((track) => {
    return {
      title: track.name,
      id: track.id,
    };
  });

  return tracks;
});

const selectedTrackId = ref(null);
const selectedParticipantId = ref("All");

const selectedParticipant = computed(() => {
  if (selectedParticipantId.value == "All") {
    return null;
  }

  return availableParticipants.value.find((r) => {
    return r.id == selectedParticipantId.value;
  });
});

const availableParticipantsQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "participants"), orderBy("bib", "asc"));
  }
});

const availableParticipants = useCollection(availableParticipantsQuery);

const participantsOnTrackOrWithoutTrack = computed(() => {
  return availableParticipants.value.filter(
    (participant) => participant.trackId == selectedTrackId.value || !participant.trackId
  );
});

const participantsForSelection = computed(() => {
  const bibNumbersAreAllNumeric = participantsOnTrackOrWithoutTrack.value.every(
    (participant) => !isNaN(participant.bib)
  );

  let participantsOrderedByBib = participantsOnTrackOrWithoutTrack.value;

  if (bibNumbersAreAllNumeric) {
    participantsOrderedByBib = participantsOnTrackOrWithoutTrack.value.sort(
      (lhs, rhs) => parseInt(lhs.bib) - parseInt(rhs.bib)
    );
  }

  return [
    {
      title: "All",
      id: "All",
    },
    ...participantsOrderedByBib
      .filter((participant) => participant.trackId == selectedTrackId.value || !participant.trackId)
      .map((participant) => {
        return {
          title: participant.bib + " - " + participant.name + (participant.isRegistered ? " [REG]" : ""),
          id: participant.id,
        };
      }),
  ];
});

const momentsQuery = computed(() => {
  if (selectedRaceId.value && selectedParticipantId.value != "All") {
    return query(
      collection(db, "races", selectedRaceId.value, "moments"),
      where("participant.id", "==", selectedParticipantId.value)
    );
  }
});

const moments = useCollection(momentsQuery);

const shotsQuery = computed(() => {
  if (selectedRaceId.value && selectedParticipantId.value != "All") {
    return query(
      collection(db, "races", selectedRaceId.value, "shots"),
      where(`recognitions.${selectedParticipantId.value}`, "!=", null)
    );
  }
});

const shots = useCollection(shotsQuery);

const cameraPoints = useCollection(
  computed(() => {
    if (selectedRaceId.value) {
      return query(collection(db, "races", selectedRaceId.value, "cameraPoints"), orderBy("rank", "asc"));
    }
  })
);

const getSelectedRace = () => {
  return races.value.find((r) => {
    return r.id === selectedRaceId.value;
  });
};

function formatDate(date) {
  if (typeof date === "string") date = new Date(date);
  if (!date) return "";
  return format(date, "{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}");
}

watch(
  selectedParticipant,
  (newValue, oldValue) => {
    if (
      oldValue == null ||
      selectedParticipantId.value == "All" ||
      !selectedParticipant.value ||
      !selectedParticipant.value.id
    )
      return;

    const participantRef = doc(db, "races", selectedRaceId.value, "participants", selectedParticipant.value.id);

    updateDoc(participantRef, {
      name: selectedParticipant.value.name,
      plannedTotalRaceTimeSec: Number(selectedParticipant.value.plannedTotalRaceTimeSec) || 0,
      isRegistered: selectedParticipant.value.isRegistered,
      isPaid: selectedParticipant.value.isPaid ? true : false,
    });
  },
  { deep: true }
);

async function downloadLiveMoments() {
  async function sleep(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
  }

  let videoUrls = moments.value
    .filter((moment) => moment.isVisible)
    .map((moment) => {
      return getVideoUrlForMoment(moment, shots.value, selectedRaceId.value) || "";
    });

  for (const video of videoUrls) {
    if (video == "") continue;

    const link = document.createElement("a");
    link.setAttribute("href", video);
    link.setAttribute("download", video.split("/").pop());
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await sleep(1);
  }
}

function getShot(shotId) {
  return shots.value.find((shot) => {
    return shot.id === shotId;
  });
}

async function toggleMomentVisiblity(moment) {
  const momentRef = doc(db, `races/${selectedRaceId.value}/moments`, moment.id);
  await updateDoc(momentRef, {
    isVisible: !moment.isVisible,
    "lifecycle.updatedAt": serverTimestamp(),
  });
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="controls-container">
        <v-select
          class="float-left control"
          label="Select race"
          density="comfortable"
          :items="racesForSelection"
          v-model="selectedRaceId"
          item-title="title"
          item-value="id"
        >
        </v-select>
        <v-select
          class="float-left control"
          v-if="selectedRaceId"
          label="Select track"
          density="comfortable"
          :items="tracksForSelection"
          v-model="selectedTrackId"
          item-title="title"
          item-value="id"
        >
        </v-select>
        <v-select
          class="float-left control"
          v-if="selectedTrackId"
          label="Select runner"
          density="comfortable"
          :items="participantsForSelection"
          v-model="selectedParticipantId"
          item-title="title"
          item-value="id"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row v-if="selectedParticipant">
      <v-col>
        <h1>
          {{ selectedParticipant.name }}
          <v-btn
            v-if="hasPostRaceVideo"
            target="_blank"
            style="margin-left: 10px"
            :href="`${siteUrl}/broadcasts/${selectedRaceId}/participants/${selectedParticipant.id}/postrace`"
          >
            Open post race video page</v-btn
          >

          <v-btn
            target="_blank"
            style="margin-left: 10px"
            :href="`${siteUrl}/broadcasts/${selectedRaceId}/participants/${selectedParticipant.id}`"
          >
            Open broadcast page</v-btn
          >
        </h1>

        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="selectedParticipant.name" label="Name" />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field v-model="selectedParticipant.plannedTotalRaceTimeSec" label="Race goal time (seconds!)" />
            </v-col>
            <v-col cols="12" md="2">
              <v-switch v-model="selectedParticipant.isRegistered" label="Registered"></v-switch>
            </v-col>
            <v-col cols="12" md="2">
              <v-switch v-model="selectedParticipant.isPaid" label="Is paid"></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                label="Post-race Video"
                prepend-icon=""
                prepend-inner-icon="mdi-video"
                v-model="postRaceVideoFileToUpload"
                :disabled="uploadIsInProgress"
              ></v-file-input>
              <div class="postrace-uploader-progress-wrapper">
                <v-progress-linear
                  v-if="uploadIsInProgress"
                  class="postrace-uploader-progress"
                  :model-value="uploadProgressPercentage"
                ></v-progress-linear>
              </div>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="cyan-darken-1"
                size="x-large"
                class="align-self-center"
                accept="video/*"
                :disabled="uploadIsInProgress"
                @click="uploadSelectedPostRaceVideo()"
                >Upload</v-btn
              >
            </v-col>
            <v-col cols="12" md="4">
              <v-btn
                v-if="hasPostRaceVideo"
                :href="getUrlForPostRaceVideo()"
                target="_blank"
                color="cyan-darken-1"
                size="x-large"
                >Download</v-btn
              >
            </v-col>
          </v-row>

          <RenderPostRaceVideo
            v-if="selectedParticipant && moments && shots && shots.length > 0 && moments.length > 0"
            :race="getSelectedRace()"
            :cameraPoints="cameraPoints"
            :tracks="availableTracks"
            :participant="selectedParticipant"
            :moments="moments"
            :shots="shots"
          />

          <v-row>
            <v-col>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left">Moment</th>
                    <th class="text-left">Camera Point</th>
                    <th class="text-left">Video length</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">
                      <v-btn
                        @click="downloadLiveMoments()"
                        target="_blank"
                        size="x-small"
                        color="cyan-darken-3"
                        style="margin-left: 5px"
                        >Download all</v-btn
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="moment in moments" :key="moment.id" :data-moment-id="moment.id">
                    <td>
                      {{ formatDate(moment.shot.video.timing.startAt.toDate()) }}
                    </td>
                    <td>{{ moment.cameraPoint.name }}</td>
                    <td>
                      {{ Math.round(moment.shot.video.timing.lengthMs / 1000) }}
                      seconds
                    </td>
                    <td>
                      <v-chip color="cyan-darken-1" v-if="moment.isVisible"> Live </v-chip>
                      <v-chip color="red-darken-1" v-if="!moment.isVisible"> Hidden </v-chip>
                    </td>
                    <td>
                      <v-btn
                        v-if="moment && shots && selectedRaceId"
                        size="small"
                        color="cyan-darken-1"
                        :href="getVideoUrlForMoment(moment, shots, selectedRaceId)"
                        prepend-icon="mdi-download"
                        target="_blank"
                        class="mr-3"
                      >
                        Download
                      </v-btn>
                      <video-trimmer
                        v-if="moment && selectedParticipant && selectedRaceId"
                        :shot="getShot(moment.shot.id)"
                        :moment="moment"
                        :participant="selectedParticipant"
                        :race="getSelectedRace()"
                      ></video-trimmer>
                      <v-btn
                        v-if="moment"
                        size="small"
                        color="cyan-darken-1"
                        :prepend-icon="moment.isVisible? 'mdi-eye-off' : 'mdi-eye'"
                        target="_blank"
                        class="ml-3"
                        @click="toggleMomentVisiblity(moment)"
                      >
                        <template v-if="moment.isVisible">
                          Hide
                        </template>
                        <template v-else>
                          Show
                        </template>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>

          <progress-table
            v-if="selectedParticipant && selectedRaceId && moments && shots"
            :participant="selectedParticipant"
            :camera-points="cameraPoints"
            :selected-race-id="selectedRaceId"
            :shots-base-url="shotsBaseUrl"
            :moments="moments"
            :shots="shots"
          >
          </progress-table>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
.postrace-uploader-progress-wrapper {
  position: relative;
}

.postrace-uploader-progress {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -22px;
}
</style>
