<script setup>
import { ref } from "vue";

const SUPPORTED_LOCALES = ["en-us", "en-gb", "hu-hu", "de-de", "de-at"];

const props = defineProps(["translation", "label"]);

const emit = defineEmits(["update"]);

const openedAddTextToShot = ref(false);

const textToUpdate = ref({});

async function updateText() {
  if (textToUpdate.value) {
    const toUpdate = textToUpdate.value;

    if (toUpdate["de-de"] && !props.translation?.["de-at"]) {
      toUpdate["de-at"] = toUpdate["de-de"];
    } else if (toUpdate["de-at"] && !props.translation?.["de-de"]) {
      toUpdate["de-de"] = toUpdate["de-at"];
    }

    if (toUpdate["en-us"] && !props.translation?.["en-gb"]) {
      toUpdate["en-gb"] = toUpdate["en-us"];
    } else if (toUpdate["en-gb"] && !props.translation?.["en-us"]) {
      toUpdate["en-us"] = toUpdate["en-gb"];
    }

    emit("update", toUpdate);
    textToUpdate.value = null;
  }
}
</script>

<template>
  <v-btn variant="tonal" @click="openedAddTextToShot = !openedAddTextToShot">
    {{ props.label }}
    <v-icon v-if="!openedAddTextToShot" class="ml-1" size="8">mdi-triangle-down</v-icon>
    <v-icon v-else class="ml-1" size="8">mdi-triangle</v-icon>
  </v-btn>

  <template v-if="openedAddTextToShot">
    <v-text-field
      class="pt-3"
      v-for="locale in SUPPORTED_LOCALES"
      :key="locale"
      :label="locale"
      hide-details
      :model-value="props.translation ? props.translation[locale] : ''"
      @update:focused="updateText()"
      @update:model-value="
        (newText) =>
          (textToUpdate = {
            [locale]: newText,
          })
      "
    ></v-text-field>
  </template>
</template>
@/libs/translation
