export const formatRaceTime = (durationInSeconds?: number): string => {
  const parts = getTimeParts(durationInSeconds);

  return parts.join(":");
};

export const formatRaceTimeWithoutSeconds = (durationInSeconds?: number): string => {
  const parts = getTimeParts(durationInSeconds);

  return parts.slice(0, 2).join(":");
};

export const formatRaceTimeOnlySeconds = (durationInSeconds?: number): string => {
  const parts = getTimeParts(durationInSeconds);
  return parts.slice(-1)[0] || "";
};

export const formatHoursAndMinutesFromDate = (date: Date): string => {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
};

export const formatDistance = (distanceInMeters?: number, showKM: boolean = true): string => {
  if (!distanceInMeters && distanceInMeters !== 0) return "";

  let distanceInKilometers = toFixed(distanceInMeters / 1000, 2);

  while (distanceInKilometers[distanceInKilometers.length - 1] === "0") {
    distanceInKilometers = distanceInKilometers.slice(0, -1);
  }

  if (distanceInKilometers[distanceInKilometers.length - 1] === ".") {
    distanceInKilometers = distanceInKilometers.slice(0, -1);
  }

  return distanceInKilometers + (showKM ? " km" : "");
};

export const formatTime = (dateTime: Date, timeZone: string) => {
  return dateTime.toLocaleTimeString("en-GB", {
    timeZone,
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

const getTimeParts = (durationInSeconds?: number): [string?, string?, string?] => {
  if (!durationInSeconds && durationInSeconds !== 0) return [];

  const hoursMultiplier = 60 * 60;
  const minutesMultiplier = 60;

  const hours = Math.floor(durationInSeconds / hoursMultiplier);
  const minutes = Math.floor((durationInSeconds % hoursMultiplier) / minutesMultiplier);
  const seconds = Math.floor(durationInSeconds % minutesMultiplier);

  return [hours.toString().padStart(2, "0"), minutes.toString().padStart(2, "0"), seconds.toString().padStart(2, "0")];
};

function toFixed(n: number, d: number): string {
  const tenToD = Math.pow(10, d);
  return (Math.round(n * tenToD) / tenToD).toFixed(d);
}
