<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";

import { getFunctions, httpsCallable } from "firebase/functions";

const timerData = ref(null);
const lastRefreshed = ref(null);

let interval;

const refreshData = async () => {
  timerData.value = await fetchTimerData();
  lastRefreshed.value = new Date();
};

interval = setInterval(async () => {
  await refreshData();
}, 60000);

onMounted(async () => {
  await refreshData();
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

const route = useRoute();

async function fetchTimerData() {
  const callable = httpsCallable(getFunctions(undefined, "europe-west1"), "fetchTimerData");
  try {
    const response = await callable({ timerUrl: timerUrl.value });
    console.log("response received", response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

const timerUrl = computed(() => {
  return route.query.timerUrl;
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Timer</h1>
      </v-col>
      <v-col>
        <v-chip v-if="lastRefreshed !== null"> Last refreshed: {{ lastRefreshed }} </v-chip>
      </v-col>
    </v-row>
    <v-table>
      <thead>
        <tr>
          <th class="text-left">Bib</th>
          <th class="text-left">Time</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="time in timerData?.data?.times">
          <td>
            <div class="font-weight-bold">{{ time.bib }}</div>
          </td>
          <td>
            <div class="font-weight-regular">{{ time.time }}</div>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<style></style>
