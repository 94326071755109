<script setup>
const props = defineProps(["recognition", "broadcastedMoments", "size"]);

const emit = defineEmits([
  "approveRecognition",
  "denyRecognition",
  "resetRecognition",
  "removeRecognition",
  "toggleBroadcastedMoment",
]);

const recognitionHasMoment = (recognition) => {
  return Boolean(recognition.momentId);
};

const recognitionIsApproved = (recognition) => {
  return recognition.isApproved === true;
};

const recognitionIsDenied = (recognition) => {
  return recognition.isApproved === false;
};

const getRecognitionStatus = (recognition) => {
  if (recognitionHasMoment(recognition)) return "broadcasted";
  if (recognitionIsApproved(recognition)) return "approved";
  if (recognitionIsDenied(recognition)) return "denied";
  return "pending";
};

const getBroadcastedMoment = (momentId) => {
  return props.broadcastedMoments.find((moment) => moment.id === momentId);
};

function approveRecognition(participantId) {
  emit("approveRecognition", participantId);
}

function denyRecognition(participantId) {
  emit("denyRecognition", participantId);
}

function resetRecognition(participantId) {
  emit("resetRecognition", participantId);
}

function removeRecognition(participantId) {
  emit("removeRecognition", participantId);
}

function toggleBroadcastedMoment(momentId, isVisible) {
  emit("toggleBroadcastedMoment", momentId, isVisible);
}

function hasRecognitionId() {
  return props.recognition.recognizerResults.some((recognition) => recognition.recognitionId);
}
</script>
<template>
  <span>
    <v-tooltip location="top" color="green" v-if="getRecognitionStatus(recognition) === 'pending'">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="cyan-darken-1"
          class="my-3 mr-3"
          @click="approveRecognition(recognition.participantId)"
        >
          <v-icon>mdi-account-check</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Approve the runner</div>
      <div class="recognition-dialog__tooltip-subtitle">as the runner appeared on the shot</div>
    </v-tooltip>
    <v-tooltip location="top" color="green" v-if="getRecognitionStatus(recognition) === 'pending'">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="red-lighten-2"
          class="my-3"
          @click="denyRecognition(recognition.participantId)"
        >
          <v-icon>mdi-account-remove</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Deny the runner</div>
      <div class="recognition-dialog__tooltip-subtitle">as the runner is not on the shot</div>
    </v-tooltip>
    <v-tooltip
      location="top"
      color="green"
      v-if="getRecognitionStatus(recognition) === 'approved' || getRecognitionStatus(recognition) === 'denied'"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="blue-grey-darken-1"
          class="my-3"
          @click="resetRecognition(recognition.participantId)"
        >
          <v-icon>mdi-undo-variant</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Reset the runner</div>
      <div class="recognition-dialog__tooltip-subtitle">so it won't be accepted nor denied</div>
    </v-tooltip>
    <v-tooltip location="top" color="green" v-if="getRecognitionStatus(recognition) != 'broadcasted' && !hasRecognitionId()">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="red-darken-1"
          class="my-3 ml-3"
          @click="removeRecognition(recognition.participantId)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Delete the runner</div>
      <div class="recognition-dialog__tooltip-subtitle">it will delete the AI and manual recognitions as well</div>
    </v-tooltip>
    <v-tooltip
      location="top"
      color="green"
      v-if="
        getRecognitionStatus(recognition) == 'broadcasted' && !getBroadcastedMoment(recognition.momentId)?.isVisible
      "
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="cyan-darken-1"
          class="my-3"
          @click="toggleBroadcastedMoment(recognition.momentId, true)"
        >
          <v-icon>mdi-broadcast</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Broadcast</div>
      <div class="recognition-dialog__tooltip-subtitle">make the broadcast visible to the users</div>
    </v-tooltip>
    <v-tooltip
      location="top"
      color="green"
      v-if="getRecognitionStatus(recognition) == 'broadcasted' && getBroadcastedMoment(recognition.momentId)?.isVisible"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :size="size"
          icon
          color="red-darken-1"
          class="my-3"
          @click="toggleBroadcastedMoment(recognition.momentId, false)"
        >
          <v-icon>mdi-broadcast-off</v-icon>
        </v-btn>
      </template>
      <div class="recognition-dialog__tooltip-title">Hide broadcast</div>
      <div class="recognition-dialog__tooltip-subtitle">hide the broadcast from the users</div>
    </v-tooltip>
  </span>
</template>
