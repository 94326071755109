<script setup>
import { useCollection, useDocument, useFirestore, useFirebaseStorage, useStorageFile } from "vuefire";
import { query, collection, orderBy, doc, setDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { ref, computed, watch } from "vue";
import { defaultTo } from "ramda";
import { domain } from "../../config";
import { format } from "light-date";
import RaceStatistics from "./RaceStatistics.vue";
import RaceResultIntegration from "./RaceResultIntegration.vue";
import Translation from "./Translation.vue";
import Creatomate from "./Creatomate.vue";
import { assetsBucket } from "../../config";
import { ref as storageRef } from "firebase/storage";
import { gpx, kml } from "@tmcw/togeojson";
import { parseRoute } from "../../../../../libs/parseRoute/index";
import { length } from "@turf/turf";

const db = useFirestore();
const storage = useFirebaseStorage();

const races = useCollection(query(collection(db, "races"), orderBy("startAt", "desc")));
const selectedRaceId = ref(null);
const raceConfigSource = computed(() => {
  if (selectedRaceId.value) {
    return doc(collection(db, "races", selectedRaceId.value, "configs"), "default");
  }
});
const selectedRace = computed(() => races.value.find(({ id }) => id === selectedRaceId.value));
const raceConfig = useDocument(raceConfigSource);

const automaticMomentGenerationForBibRecognitionEnabled = computed(() =>
  raceConfig.value ? defaultTo(true, raceConfig.value.automaticMomentGenerationForBibRecognitionEnabled) : true
);

const triggerRecognitionOnShotCreationEnabled = computed(() =>
  raceConfig.value ? defaultTo(true, raceConfig.value.triggerRecognitionOnShotCreationEnabled) : true
);

const minimumBibOccurrencesForDecision = computed(() =>
  raceConfig.value ? defaultTo(30, raceConfig.value.minimumBibOccurrencesForDecision) : 30
);

const blockClaimForMaxSec = computed(() =>
  raceConfig.value ? defaultTo(120, raceConfig.value.blockClaimForMaxSec) : 120
);

const personSimilarityEnabled = computed(() =>
  raceConfig.value ? defaultTo(true, raceConfig.value.personSimilarityEnabled) : true
);

const personSimilarityDecisiveThreshold = computed(() =>
  raceConfig.value ? defaultTo(0.03, raceConfig.value.personSimilarityDecisiveThreshold) : 0.03
);

const personSimilarBibDecisiveThreshold = computed(() =>
  raceConfig.value ? defaultTo(0.4, raceConfig.value.personSimilarBibDecisiveThreshold) : 0.4
);

const personSimilarBibDecisiveMinDiff = computed(() =>
  raceConfig.value ? defaultTo(0.2, raceConfig.value.personSimilarBibDecisiveMinDiff) : 0.2
);

const trueOrFalseSelect = [
  { title: "true", value: true },
  { title: "false", value: false },
];

const googleCloudVisionApiEndpointSelect = [
  "vision.googleapis.com",
  "eu-vision.googleapis.com",
  "us-vision.googleapis.com",
];
const blockClaimForMaxSecSelect = [
  { title: "Do not block", value: 0 },
  { title: "30 seconds", value: 30 },
  { title: "1 minute", value: 60 },
  { title: "2 minutes", value: 120 },
];

const minimumBibOccurrencesForDecisionForSelect = [];
for (let i = 1; i <= 70; i++) {
  minimumBibOccurrencesForDecisionForSelect.push({ title: i, value: i });
}

const googleCloudVisionApiEndpoint = computed(() =>
  raceConfig.value
    ? defaultTo("vision.googleapis.com", raceConfig.value.googleCloudVisionApiEndpoint)
    : "vision.googleapis.com"
);

async function updateBlockClaimForMaxSec(value) {
  await setDoc(raceConfigSource.value, { blockClaimForMaxSec: value }, { merge: true });
}

async function updateAutomaticMomentGenerationForBibRecognitionEnabled(value) {
  await setDoc(raceConfigSource.value, { automaticMomentGenerationForBibRecognitionEnabled: value }, { merge: true });
}

async function updateTriggerRecognitionOnShotCreationEnabled(value) {
  await setDoc(raceConfigSource.value, { triggerRecognitionOnShotCreationEnabled: value }, { merge: true });
}

async function updateminimumBibOccurrencesForDecision(value) {
  await setDoc(raceConfigSource.value, { minimumBibOccurrencesForDecision: value }, { merge: true });
}

async function updategoogleCloudVisionApiEndpoint(value) {
  await setDoc(raceConfigSource.value, { googleCloudVisionApiEndpoint: value }, { merge: true });
}

async function updatePersonSimilarityEnabled(value) {
  await setDoc(raceConfigSource.value, { personSimilarityEnabled: value }, { merge: true });
}

async function updatePersonSimilarBibDecisiveMinDiff(value) {
  await setDoc(raceConfigSource.value, { personSimilarBibDecisiveMinDiff: value }, { merge: true });
}

async function updatePersonSimilarBibDecisiveThreshold(value) {
  await setDoc(raceConfigSource.value, { personSimilarBibDecisiveThreshold: value }, { merge: true });
}

async function updatePersonSimilarityDecisiveThreshold(value) {
  await setDoc(raceConfigSource.value, { personSimilarityDecisiveThreshold: value }, { merge: true });
}

async function updateFeedbackUrl(value) {
  const data = Object.entries(value).reduce(
    (updateData, [locale, value]) => ({ ...updateData, [`feedbackUrl.${locale}`]: value }),
    {}
  );
  await updateDoc(doc(db, "races", selectedRaceId.value), data);
}

const sheetsPending = ref(false);

async function generateRaceReport() {
  console.log("Generating Race Report");
  const callable = httpsCallable(getFunctions(undefined, domain), "api/reportGenerator", { timeout: 540000 });
  try {
    sheetsPending.value = true;

    const response = await callable({ raceId: selectedRaceId.value });
    console.log(response); // this is a necessary log, an emotional support for Balazs

    sheetsPending.value = false;

    processSheets(response.data.sheets);
  } catch (err) {
    console.log(err);
    sheetsPending.value = false;
  }
}

const processSheets = (sheets) => {
  Object.entries(sheets).forEach(([sheetName, sheetCsv]) => {
    const blob = new Blob([sheetCsv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = sheetName + ".csv";
    document.body.appendChild(link);
    link.click();
  });
};

const showGenerateReport = computed(() => {
  return !sheetsPending.value && selectedRaceId.value;
});

const generalBroadcastAdminUrl = computed(
  () => `${__SITE_URL__}broadcasts/${selectedRaceId.value}/admin/shots/general`
);

const updateShopifyProductHandle = async (value) => {
  await setDoc(doc(db, "races", selectedRaceId.value), { shopifyProductHandle: value }, { merge: true });
};

const tracksQuery = computed(() => {
  if (selectedRaceId.value) {
    return query(collection(db, "races", selectedRaceId.value, "tracks"));
  }
});

const tracks = useCollection(tracksQuery);

const updateShopifyProductVariantId = async (raceId, trackId, variantId) => {
  await setDoc(
    doc(db, "races", raceId, "tracks", trackId),
    { shopifyProductVariantId: parseInt(variantId) },
    { merge: true }
  );
};

const updateShopifyProductId = async (raceId, productId) => {
  await setDoc(doc(db, "races", raceId), { shopifyProductId: parseInt(productId) }, { merge: true });
};


const mapsToUpload = ref({});
const mapUploadIsInProgress = ref({});
const mapUploadProgressPercentages = ref({});

const uploadMap = async (track, file) => {
  mapUploadProgressPercentages.value[track.id] = 0;

  const raceId = selectedRaceId.value;

  const datePrefix = format(new Date(), "{yyyy}{MM}{dd}{HH}{mm}{ss}");
  const uploadedFilePath = `maps/${raceId}_${track.id}_${datePrefix}.json`;

  const fileRef = storageRef(storage, `gs://${assetsBucket}/${uploadedFilePath}`);
  const fileUrl = `https://storage.googleapis.com/${assetsBucket}/${uploadedFilePath}`;

  const { uploadProgress, updateMetadata, upload } = useStorageFile(fileRef);

  watch(uploadProgress, (progress) => {
    mapUploadProgressPercentages.value[track.id] = Math.round(uploadProgress.value * 100);

    if (progress == 1) {
      mapUploadIsInProgress.value[track.id] = false;
      mapsToUpload.value[track.id] = null;
    }
  });

  const content = await new Response(file).text();
  const geoJSON = await anyMapToGeoJSON(content);
  const geoJSONAsBlob = new Blob([JSON.stringify(geoJSON)], { type: "application/json" });
  let trackLengthM = 0;

  try {
    const trackRoute = parseRoute(geoJSON);
    trackLengthM = length(trackRoute, { units: "meters" });
  } catch (e) {
    console.error("Error while calculating endpoint with distance", e);
    alert("Can't determine route length");
    return;
  }

  await upload(geoJSONAsBlob);

  await updateMetadata({
    contentDisposition: "attachment",
  });

  const trackRef = doc(db, "races", selectedRaceId.value, "tracks", track.id);

  await updateDoc(trackRef, {
    "mapUrl": fileUrl,
    "totalDistanceM": trackLengthM,
    "lifecycle.updatedAt": serverTimestamp(),
  });
};

const uploadMapToTrack = async(track) => {
  mapUploadIsInProgress.value[track.id] = true;
  await uploadMap(track, mapsToUpload.value[track.id]);
};

async function anyMapToGeoJSON(mapContent) {
  const isGPX = isGpxString(mapContent);
  const isKML = isKmlString(mapContent);

  if (isGPX || isKML) {
    const domParser = new DOMParser();
    const xml = domParser.parseFromString(mapContent, "text/xml");
    return (isGPX ? gpx(xml) : kml(xml));
  }

  return JSON.parse(mapContent);
}

function isGpxString(str) {
  try {
    // Parse the string as an XML document
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(str, "application/xml");

    // Check for parsing errors
    if (xmlDoc.getElementsByTagName("parsererror").length > 0) {
      return false;
    }

    // Check if the root element is <gpx> and has the correct namespace
    const rootElement = xmlDoc.documentElement;
    if (rootElement.nodeName === "gpx" && rootElement.namespaceURI === "http://www.topografix.com/GPX/1/1") {
      return true;
    }

    return false;
  } catch (e) {
    // If an error occurs, the string is not a valid GPX
    return false;
  }
}

function isKmlString(str) {
  try {
    // Parse the string as an XML document
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(str, "application/xml");

    // Check for parsing errors
    if (xmlDoc.getElementsByTagName("parsererror").length > 0) {
      return false;
    }

    // Check if the root element is <kml> and has the correct namespace
    const rootElement = xmlDoc.documentElement;
    if (rootElement.nodeName === "kml" && rootElement.namespaceURI === "http://www.opengis.net/kml/2.2") {
      return true;
    }

    return false;
  } catch (e) {
    // If an error occurs, the string is not a valid KML
    return false;
  }
}

</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select class="float-left control" label="Select race" density="comfortable" :items="races" v-model="selectedRaceId" item-title="name" item-value="id">
        </v-select>
      </v-col>
    </v-row>
    <template v-if="selectedRaceId">
      <v-row>
        <v-col class="flex-grow-0">
          <v-btn color="primary" target="_blank" :href="generalBroadcastAdminUrl">
            <v-icon class="mr-1">mdi-broadcast</v-icon>
            General race broadcast admin
          </v-btn>
        </v-col>
        <v-col class="flex-grow-0">
          <v-btn v-if="showGenerateReport" @click="generateRaceReport()" target="_blank" color="primary">
            <v-icon class="mr-1">mdi-chart-bar</v-icon>
            Generate race report
          </v-btn>
          <v-btn v-else target="_blank" color="primary" disabled>Generating...</v-btn>
        </v-col>
        <v-col class="flex-grow-0">
          <creatomate :race-id="selectedRaceId"></creatomate>
        </v-col>
      </v-row>
      <v-row>
        <v-col><strong>AI Recognition</strong></v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0">
          <v-select class="float-left control" style="width: 250px !important; max-width: 250px !important" label="Run recognition" density="comfortable" hide-details="true" :items="trueOrFalseSelect"
            :model-value="triggerRecognitionOnShotCreationEnabled" item-title="title" item-value="value" @update:model-value="updateTriggerRecognitionOnShotCreationEnabled">
          </v-select>
        </v-col>
        <v-col class="flex-grow-0">
          <v-select class="float-left control" style="width: 250px !important; max-width: 250px !important" label="Automatic moment generation" density="comfortable" hide-details="true" :items="trueOrFalseSelect"
            :model-value="automaticMomentGenerationForBibRecognitionEnabled" item-title="title" item-value="value" @update:model-value="updateAutomaticMomentGenerationForBibRecognitionEnabled">
          </v-select>
        </v-col>
        <v-col class="flex-grow-0">
          <v-select class="float-left control" style="width: 250px !important; max-width: 250px !important" label="Min. bib occurrences for decision" density="comfortable" hide-details="true"
            :items="minimumBibOccurrencesForDecisionForSelect" :model-value="minimumBibOccurrencesForDecision" item-title="title" item-value="value" @update:model-value="updateminimumBibOccurrencesForDecision">
          </v-select>
        </v-col>
        <v-col class="flex-grow-0">
          <v-select class="float-left control" style="width: 250px !important; max-width: 250px !important" label="Google Vision API Location" density="comfortable" hide-details="true"
            :items="googleCloudVisionApiEndpointSelect" :model-value="googleCloudVisionApiEndpoint" item-title="title" item-value="value" @update:model-value="updategoogleCloudVisionApiEndpoint">
          </v-select>
        </v-col>
        <v-col class="flex-grow-0">
          <v-select class="float-left control" style="width: 250px !important; max-width: 250px !important" label="Block claim for maximum" density="comfortable" hide-details="true" :items="blockClaimForMaxSecSelect"
            :model-value="blockClaimForMaxSec" item-title="title" item-value="value" @update:model-value="updateBlockClaimForMaxSec">
          </v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pb-0">
          <strong>Race config</strong>
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="tracks">
        <v-col>
          <strong style="font-size: 90%">Tracks</strong>
        </v-col>
      </v-row>
      <v-row v-for="track in tracks" v-if="tracks">
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-file-input :label="`Map for ${track.name}`" prepend-icon="" prepend-inner-icon="mdi-map" v-model="mapsToUpload[track.id]" :disabled="mapUploadIsInProgress[track.id]"></v-file-input>
          <div class="uploader-progress-wrapper">
            <v-progress-linear v-if="mapUploadIsInProgress[track.id]" class="uploader-progress" :model-value="mapUploadProgressPercentages[track.id]"></v-progress-linear>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="pt-0 pb-0">
          <v-btn color="cyan-darken-1" size="x-large" class="align-self-center" :disabled="mapUploadIsInProgress[track.id] || !mapsToUpload[track.id]" @click="uploadMapToTrack(track)">Upload</v-btn>
        </v-col>
      </v-row>


      <v-row class="mt-4" v-if="tracks">
        <v-col class="pb-0">
          <strong style="font-size: 90%">AI settings</strong>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-0">
          <v-select class="pt-3" label="Person similarity enabled" density="comfortable" hide-details="true" :items="trueOrFalseSelect" :model-value="personSimilarityEnabled" item-title="title" item-value="value"
            @update:model-value="updatePersonSimilarityEnabled">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-text-field class="pt-3" type="number" label="Person similarity bib decisive min diff" hide-details :model-value="personSimilarBibDecisiveMinDiff" @update:model-value="updatePersonSimilarBibDecisiveMinDiff">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-text-field class="pt-3" type="number" label="Person similar bib decisive threshold" hide-details :model-value="personSimilarBibDecisiveThreshold"
            @update:model-value="updatePersonSimilarBibDecisiveThreshold">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-text-field class="pt-3" type="number" label="Person similarity decisive threshold" hide-details :model-value="personSimilarityDecisiveThreshold" @update:model-value="updatePersonSimilarityDecisiveThreshold">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="tracks">
        <v-col class="pb-0">
          <strong style="font-size: 90%">Shopify</strong>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-0">
          <v-text-field class="pt-3" label="Shopify product handle" hide-details :model-value="selectedRace.shopifyProductHandle" @update:model-value="updateShopifyProductHandle"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pb-0">
          <strong style="font-size: 80%">Shopify product ID</strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-text-field class="pt-3" label="Product ID" hide-details :model-value="selectedRace.shopifyProductId" @update:model-value="async (productId) => {
              await updateShopifyProductId(selectedRaceId, productId);
            }
            "></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4" v-if="tracks">
        <v-col class="pb-0">
          <strong style="font-size: 80%">Shopify product variant IDs</strong>
        </v-col>
      </v-row>
      <v-row v-for="track in tracks" v-if="tracks">
        <v-col class="pt-0">
          <v-text-field class="pt-3" :label="track.name" hide-details :model-value="track.shopifyProductVariantId" @update:model-value="async (variantId) => {
              await updateShopifyProductVariantId(selectedRaceId, track.id, variantId);
            }
            "></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col>
          <Translation :translation="selectedRace.feedbackUrl" :label="'Feedback url'" @update="updateFeedbackUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex-grow-0"> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <RaceStatistics :raceId="selectedRaceId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <RaceResultIntegration :raceId="selectedRaceId" :raceConfig="raceConfig" :tracks="tracks" />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<style>
.uploader-progress-wrapper {
  position: relative;
}

.uploader-progress {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -22px;
}
</style>
