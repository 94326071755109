<script setup>
import { useCollection, useDocument, useFirestore } from "vuefire";
import { query, collection, where, doc } from "firebase/firestore";
import { computed } from "vue";

const db = useFirestore();

const props = defineProps(["raceId"]);

const momentsToShowSource = computed(() =>
  useCollection(
    query(
      collection(db, "races", props.raceId, "moments"),
      where("isVisible", "==", true),
      where("cameraPointTrack.isStart", "==", false)
    )
  )
);
const momentsToShowNumber = computed(() => momentsToShowSource.value.value.length);

const statisticsSource = computed(() => useDocument(doc(db, "races", props.raceId, "statistics", "default")));
const misrecognitionsCount = computed(() => statisticsSource.value.value?.misrecognitionsCount || 0);

const shotsSource = computed(() => useCollection(collection(db, "races", props.raceId, "shots")));
const shotsCount = computed(() => shotsSource.value.value.length);
const manualWorkNeededShotsCount = computed(
  () => shotsSource.value.value.filter((shot) => shot.recognitionStatus === "AUTO_DONE").length
);
</script>
<template>
  <v-container class="ma-0 pa-0">
    <v-row
      ><v-col><strong>Statistics</strong></v-col></v-row
    >
    <v-row>
      <v-col>Number of visible moments: </v-col><v-col>{{ momentsToShowNumber }}</v-col>
    </v-row>
    <v-row>
      <v-col>Number of misrecognitions: </v-col><v-col>{{ misrecognitionsCount }}</v-col>
    </v-row>
    <v-row>
      <v-col>Number of shots: </v-col><v-col>{{ shotsCount }}</v-col>
    </v-row>
    <v-row>
      <v-col>Number of shots with AI Recgonition Done: manual work needed </v-col
      ><v-col>{{ manualWorkNeededShotsCount }}</v-col>
    </v-row>
  </v-container>
</template>
