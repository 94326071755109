import { Moment, Participant, ProgressItem, Race } from "../../../../common/domain/types";
import { Element, Template, TemplateTag } from "../../types";
import { changeSourceInComposition } from "../changeSourceInComposition";

export const updateStartElement = (template: Template, race: Race, participant: Participant, moments: Moment[], shotsUrl: string): Template => {
  const startProgressItem = getStartProgressItem(participant);
  const startElementIndex = getStartElementIndex(template);

  if (!startProgressItem || startElementIndex < 0) {
    return template;
  }

  return updateProgressElements(template, race, moments, startElementIndex, [startProgressItem], shotsUrl);
};

export const updateFinishElement = (template: Template, race: Race, participant: Participant, moments: Moment[], shotsUrl: string): Template => {
  const finishProgressItem = getFinishProgressItem(participant);
  const finishElementIndex = getFinishElementIndex(template);

  if (!finishProgressItem || finishElementIndex < 0) {
    return template;
  }

  return updateProgressElements(template, race, moments, finishElementIndex, [finishProgressItem], shotsUrl);
};

export const updateIntermediateProgressElements = (template: Template, race: Race, participant: Participant, moments: Moment[], shotsUrl: string): Template => {
  const progressItems = getIntermediateProgressItems(participant);
  const progressElementIndex = getProgressElementIndex(template);

  if (progressElementIndex < 0) {
    return template;
  }

  return updateProgressElements(template, race, moments, progressElementIndex, progressItems, shotsUrl);
};

function updateProgressElements(template: Template, race: Race, moments: Moment[], progressElementIndex: number, progressItems: ProgressItem[], shotsUrl: string): Template {
  const originalProgressElement = template.elements![progressElementIndex];

  const progressElements = [];

  for (const progressItem of progressItems) {
    if (progressItem.momentId) {
      const moment = getMomentById(moments, progressItem.momentId);

      if (!moment) {
        return template;
      }

      const element = changeSourceInComposition({
        composition: originalProgressElement,
        videoSource: moment.shot.video.transcodedFile!.name!,
        videoDuration: moment.shot.video.timing.participantAppearedForSec || (moment.shot.video.timing.lengthMs || 0) / 1000,
        videoTrimStart: moment.shot.video.timing.participantAppearedAtSec || 0,
        happenedAt: progressItem.happenedAt,
        raceTimeSec: progressItem.fromStartTimeSec,
        timeZone: race.timeZone,
        distanceM: progressItem.distanceM,
        shotsUrl,
      });

      progressElements.push(element);
    }
  }

  const elementsBeforeOriginalProgressElement = template.elements!.slice(0, progressElementIndex);
  const elementsAfterOriginalProgressElement = template.elements!.slice(progressElementIndex + 1);

  return {
    ...template,
    elements: [
      ...elementsBeforeOriginalProgressElement,
      ...progressElements,
      ...elementsAfterOriginalProgressElement,
    ],
  };
}

function getStartElementIndex(template: Template): number {
  return getElementIndexWithTag(template, TemplateTag.START_ELEMENT);
}

function getFinishElementIndex(template: Template): number {
  return getElementIndexWithTag(template, TemplateTag.FINISH_ELEMENT);
}

function getProgressElementIndex(template: Template): number {
  return getElementIndexWithTag(template, TemplateTag.PROGRESS_ELEMENT);
}

function getElementIndexWithTag(template: Template, tag: string): number {
  return (template.elements || []).findIndex((element: Element) => element.name?.includes(tag));
}

function getStartProgressItem(participant: Participant): ProgressItem | undefined {
  return participant.progress?.find((progressItem) => progressItem.isStart);
}

function getFinishProgressItem(participant: Participant): ProgressItem | undefined {
  return participant.progress?.find((progressItem) => progressItem.isFinish);
}

function getIntermediateProgressItems(participant: Participant): ProgressItem[] {
  return participant.progress?.filter((progressItem) => !progressItem.isStart && !progressItem.isFinish) || [];
}

function getMomentById(moments: Moment[], momentId: string): Moment | undefined {
  return moments.find((moment) => moment.id === momentId);
}
