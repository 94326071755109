<script setup>
import { ref, computed, watch } from "vue";
import { useFirestore } from "vuefire";
import { doc, getDoc, setDoc, serverTimestamp, arrayUnion, updateDoc } from "firebase/firestore";

const db = useFirestore();

const { admin, races } = defineProps(["admin", "races"]);

const search = ref("");
const showOnlySelected = ref(false);

const filteredRaces = computed(() => {
  return (races || []).filter((race) => {
    if (showOnlySelected.value && admin.raceRoles && !admin.raceRoles[race.id]) return false;
    if (!search.value) return true;
    return race.name.toLowerCase().includes(search.value.toLowerCase());
  });
});

const roles = [
  { title: "Owner", value: "owner" },
  { title: "Editor", value: "editor" },
  { title: "Videographer", value: "videographer" },
];

const changeRolesForUser = async (userId, raceId, roles) => {
  const memberDocRef = doc(db, `races/${raceId}/members/${userId}`);
  const memberDocSnapshot = await getDoc(memberDocRef);
  if (!memberDocSnapshot.exists()) {
    await setDoc(memberDocRef, {
      raceId: raceId,
      uid: userId,
      roles,
      status: "active",
      events: [
        {
          date: new Date(),
          action: "create",
          actorUid: userId,
          roles,
        },
      ],
      lifecycle: {
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
    });
  } else {
    await updateDoc(memberDocRef, {
      roles,
      events: arrayUnion({
        date: new Date(),
        action: "change",
        actorUid: userId,
        roles,
      }),
      "lifecycle.updatedAt": serverTimestamp(),
    });

  }
};

const adminRaceRoles = computed(() => {
  return admin.raceRoles || {};
});

const adminRaces = computed(() => {
  return (races || []).filter((race) => {
    return race.memberRoles && race.memberRoles[admin.id];
  });
});

</script>

<template>
  <v-dialog width="800">
    <template v-slot:activator="{ props }">

        <template v-if="adminRaces.length === 0">
          No race
        </template>
        <template v-else-if="adminRaces.length === 1">
          {{ adminRaces[0].name }}
        </template>
        <template v-else>
          {{ adminRaces.length }} races
        </template>

      <v-btn icon="mdi-text-box-edit-outline" size="xsmall" v-bind="props" class="ml-2"></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Select race roles for admin">
        <v-card-text>
          <div class="d-flex">
            <v-text-field class="d-inline-block mr-5" hide-details style="max-width: 300px;" v-model="search" label="Search race" outlined density="compact"></v-text-field>

            <v-switch
              class="control mt-n2"
              v-model="showOnlySelected"
              label="Show only selected races"
            >
            </v-switch>
          </div>

          <v-table>
            <thead>
              <tr>
                <th>
                  Race
                </th>
                <th>Roles</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="race in filteredRaces">
                <td>
                  <div style="width: 300px;">
                    {{ race.name }}
                  </div>
                </td>
                <td>
                  <v-select style="width: 300px;" :hide-details="true" :items="roles" v-model="adminRaceRoles[race.id]" density="compact" multiple
                    @update:focused="(isFocused) => !isFocused && changeRolesForUser(admin.id, race.id, adminRaceRoles[race.id])">
                  </v-select>
                </td>
              </tr>
            </tbody>
          </v-table>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text="Close"
            @click="
              (isActive.value = false);
            "
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
