import { ChangeSourceInCompositionOptions, Element, TemplateTag, TimingProperties } from "../../types";
import { modifyDeepByName } from "..";
import { formatRaceTime, formatDistance, formatTime } from "../../../format";

import * as crypto from "crypto";
import { cloneDeep } from "lodash-es";

export function changeSourceInComposition(options: ChangeSourceInCompositionOptions) {
  const { composition: originalComposition, videoSource, videoTrimStart, videoDuration, raceTimeSec, distanceM, happenedAt, timeZone, shotsUrl } = options;
  let composition = cloneDeep(originalComposition);

  const durationDiff = updateTargetVideo(composition, getUrlOfVideo(shotsUrl!, videoSource), videoTrimStart, videoDuration);

  if (durationDiff) {
    updateTimingPropertiesOfElements(composition, TemplateTag.CHANGE_TIME, "time", durationDiff);
    updateTimingPropertiesOfElements(composition, TemplateTag.CHANGE_DURATION, "duration", durationDiff);
    composition.duration = (composition.duration || 0) + durationDiff;
  }

  if (distanceM) {
    composition = modifyDeepByName(composition, TemplateTag.DISTANCE, "text", formatDistance(distanceM));
  }

  if (raceTimeSec) {
    composition = modifyDeepByName(composition, TemplateTag.RACE_TIME, "text", formatRaceTime(raceTimeSec));
  }

  if (happenedAt && timeZone) {
    composition = modifyDeepByName(composition, TemplateTag.HAPPENED_AT, "text", formatTime(happenedAt, timeZone));
  }

  updateElementIdInComposition(composition);

  return composition;
}

function updateTimingPropertiesOfElements(element: Element, targetElementNameContains: string, targetProperty: TimingProperties, modifyValueBy: number) {
  if (element.name && element.name.includes(targetElementNameContains)) {
    element[targetProperty] = (element[targetProperty] || 0) + modifyValueBy;
  }

  if (element.elements) {
    for (const subElement of element.elements) {
      updateTimingPropertiesOfElements(subElement, targetElementNameContains, targetProperty, modifyValueBy);
    }
  }
}

function updateTargetVideo(element: Element, source: string, trimStart: number, duration: number, currentTime: number = 0): number | null {
  const elementTime = element.time || 0;

  currentTime += elementTime;

  if (element.name?.includes(TemplateTag.VIDEO)) {
    const elementDuration = element.duration || 0;
    const durationChange = duration - elementDuration;
    element.trim_start = trimStart;
    element.duration = duration;
    element.source = source;
    return durationChange;
  }

  if (element.elements) {
    for (const subElement of element.elements) {
      const result = updateTargetVideo(subElement, source, trimStart, duration, currentTime);
      if (result) return result;
    }
  }
  return null;
}

function updateElementIdInComposition(element: Element) {
  const uuid = getCrypto().randomUUID();
  element.id = uuid;

  for (const childElement of element.elements || []) {
    updateElementIdInComposition(childElement);
  }
}

function getCrypto() {
  if (typeof window != "undefined" && window.crypto) {
    return window.crypto;
  } else {
    return crypto;
  }
}

function getUrlOfVideo(shotsUrl: string, fileName: string) {
  const baseUrl = shotsUrl || "";
  const separator = baseUrl.endsWith("/") ? "" : "/";
  return `${baseUrl}${separator}${fileName}`;
}
