export function convertTimestampsToDates(obj) {
  if (obj === null || obj === undefined) {
    return obj;
  }

  if (typeof obj.toDate === "function") {
    return obj.toDate();
  }

  if (Array.isArray(obj)) {
    return obj.map(convertTimestampsToDates);
  }

  if (typeof obj === "object") {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, convertTimestampsToDates(value)]));
  }

  return obj;
}
