import { Element, Template, TemplateTag } from "../../types";
import { cloneDeep } from "lodash-es";

export function updateRootDurations(originalTemplate: Template): Template {
  const template = cloneDeep(originalTemplate);

  const elements = template.elements || [];
  const elementsWithoutSkipRootTiming = elements.filter((element: Element) => !element.name?.includes(TemplateTag.SKIP_ROOT_TIMING));

  template.duration = longestElementDuration(elementsWithoutSkipRootTiming);

  return template;
}

function longestElementDuration(elements: Element[]): number {
  return elements.reduce((max, element) => Math.max(max, (element.time || 0) + (element.duration || 0)), 0);
}
