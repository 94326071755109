<script setup>
import { query } from "firebase/database";
import { collection, doc, getDoc, getDocs, limit, where } from "firebase/firestore";
import { defineProps, ref } from "vue";
import { useFirestore } from "vuefire";
import { getFrameUrl, middleFrameIndex } from "../libs/recognitionHelpers";

const db = useFirestore();

const props = defineProps([
  "bib",
  "raceId",
  "recognitionIdToOmit"
]);

const isLoading = ref(false);
const frames = ref([]);

const onTooltipStateChange = async (state) => {
  if (state && props.raceId && props.bib) {
    frames.value = [];
    isLoading.value = true;

    const momentsRef = query(collection(db, "races", props.raceId, "moments"), where("participant.bib", "==", props.bib));
    const momentDocs = (await getDocs(momentsRef)).docs.map((doc) => ({id: doc.id, ...doc.data()}));

    const moments = momentDocs
      .filter((moment) => moment.isVisible && moment.recognition?.id && moment.recognition.id !== props.recognitionIdToOmit);

    if (moments.length == 0) {
      isLoading.value = false;
      return;
    }

    if (moments.length > 4) {
      moments.length = 4;
    }

    for (const moment of moments) {
      const recognitionRef = doc(db, "races", props.raceId, "recognitions", moment.recognition.id);
      const recognition = (await getDoc(recognitionRef)).data();

      if (!recognition) continue;

      frames.value.push({
        shotId: recognition.shot.id,
        url: getFrameUrl(recognition, middleFrameIndex(recognition))
      });
    }

    isLoading.value = false;
  }
};
</script>

<template>
  <v-tooltip activator="parent" location="right" open-delay="100" @update:modelValue="onTooltipStateChange">
    <v-progress-circular v-if="isLoading" indeterminate size="24" class="my-2"></v-progress-circular>
    <div v-else-if="frames.length > 0" class="d-flex mx-n3">
      <div v-for="frame in frames" class="mx-1" style="position: relative;">
        <div style="font-size: 10px;" class="mb-1">
          <span class="font-weight-bold">Shot:</span>
          {{ frame.shotId.substring(0, 5) }}...
        </div>
        <img :src="frame.url" style="max-width: 200px; max-height: 300px;" />
      </div>
    </div>
    <div v-else>
      No recognition for this runner
    </div>
  </v-tooltip>
</template>
