<script setup>
import { useCollection, useDocument, useFirestore } from "vuefire";
import { query, collection, orderBy, doc, setDoc, updateDoc } from "firebase/firestore";
import { ref, watch, computed } from "vue";

const props = defineProps([
  "raceId",
]);

const db = useFirestore();

const tracks = useCollection(computed(() => query(collection(db, `races/${props.raceId}/tracks`), orderBy("rank", "asc"))));
const creatomateConfig = useDocument(computed(() => doc(db, `races/${props.raceId}/configs/creatomate`)));

watch([() => creatomateConfig.value, () => tracks.value], () => {
  loader.value = false;

  if (tracks.value.length === 0) {
    return;
  }

  trackConfig.value = tracks.value.map((track) => {
    return {
      id: track.id,
      name: track.name,
      template: creatomateConfig.value?.templates?.[track.id] || "",
    };
  })
});

const dialog = ref(false);
const loader = ref(true);
const trackConfig = ref(null);

async function close() {
  dialog.value = false;
  await new Promise((resolve) => setTimeout(resolve, 1000));
}

async function save() {
  if (tracks.value.length === 0) {
    return;
  }

  const config = {
    templates: {}
  };

  trackConfig.value = trackConfig.value.map((track) => {
    return { ...track, template: track.template.trim() };
  });

  trackConfig.value.forEach((track) => {
    config.templates[track.id] = track.template;
  });

  loader.value = true;

  await setDoc(doc(db, `races/${props.raceId}/configs/creatomate`), config);
  await close();

  loader.value = false;
}
</script>

<template>
  <v-btn color="primary">
    <v-icon class="mr-1">mdi-motion-play</v-icon>
    Creatomate
    <v-dialog activator="parent" v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          Creatomate settings
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="loader" indeterminate />
          <template v-else>
            <div v-if="tracks.length === 0">
              No tracks found or no rank set to the tracks.
            </div>
            <div v-else class="mb-6" v-for="track in trackConfig">
                Template for <strong>{{ track.name }}</strong>
                <v-textarea hide-details="true" rows="10" class="mt-1 small-textarea" v-model="track.template" label="Template JSON" />
            </div>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="plain" @click="close()">Close</v-btn>
          <v-btn color="primary" @click="save()">Save and close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<style>
.small-textarea * {
  font-size: 90% !important;
}
</style>
