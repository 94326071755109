export const buildEnvironment = __BUILD_ENVIRONMENT__;
export const runEnvironment = __RUN_ENV__;
export const sentryDSN = __SENTRY_DSN__;
export const firebaseConfig = __FIREBASE_CONFIG__;
export const domain = __DOMAIN__;
export const shotsBaseUrl = __SHOTS_BASE_URL__;
export const recognitionsBaseUrl = __RECOGNITIONS_BASE_URL__;
export const postRaceVideosBaseUrl = __POST_RACE_VIDEOS_BASE_URL__;
export const postRaceVideosBucketUrl = __POST_RACE_VIDEOS_BUCKET_URL__;
export const appBucket = __APP_BUCKET__;
export const assetsBucket = __ASSETS_BUCKET__;
