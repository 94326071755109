<script setup>
import { ref, computed } from "vue";
import { useFirestore, useDocument } from "vuefire";
import {
  getTracksForShot,
  showStartBoradcastForTracks,
  hideStartBoradcastForTracks,
} from "./broadcastStart";
import { doc } from "firebase/firestore";

const db = useFirestore();

const { shotId, raceId } = defineProps(["shotId", "raceId"]);

const selectedTracks = ref([]);
const tracks = ref([]);
const tracksLoadingState = ref("LOADING");

const isTracksLoading = computed(() => tracksLoadingState.value === "LOADING");
const isTracksError = computed(() => tracksLoadingState.value === "ERROR");

async function show() {
  controlsDisabled.value = true;
  const trackIds = getTrackIds();
  await showStartBoradcastForTracks(db, shotId, raceId, trackIds);
  clear();
  controlsDisabled.value = false;
}

async function hide() {
  controlsDisabled.value = true;
  const trackIds = getTrackIds();
  await hideStartBoradcastForTracks(db, shotId, raceId, trackIds);
  clear();
  controlsDisabled.value = false;
}

async function loadTracks() {
  if (tracks.value.length > 0) {
    return;
  }
  try {
    tracksLoadingState.value = "LOADING";
    const shotTracks = await getTracksForShot(db, shotId, raceId);
    tracks.value = shotTracks;
    tracksLoadingState.value = "LOADED";
  } catch (e) {
    tracksLoadingState.value = "ERROR";
  }
}

function getTrackIds() {
  return selectedTracks.value.map(({ id }) => id);
}

function clear() {
  selectedTracks.value = [];
}

const controlsDisabled = ref(false);

const shotQuery = computed(() => {
  if (raceId && shotId) {
    return doc(db, "races", raceId, "shots", shotId);
  }
});

const shot = useDocument(shotQuery);

const isBroadcastInProgress = computed(() => {
  if (!shot.value) return false;

  const recognitions = shot.value.recognitions || {};

  return Object.values(recognitions).some((recognition) => {
    const isStartRecognition = isStartBroadcastRecognition(recognition);
    const isMomentGeneratorInProgress = hasUnfinishedMoment(recognition);

    return isStartRecognition && isMomentGeneratorInProgress;
  });
});

const isStartBroadcastRecognition = (recognition) => {
  return recognition.recognizerResults.some((recognizerResult) => recognizerResult.type === "start")
};

const hasUnfinishedMoment = (recognition) => {
  return recognition.isApproved && recognition.momentId === undefined;
};

const isTranscodedVideoReady = computed(() => {
  return !!shot.value?.video?.transcodedFile?.name
});

const broadcastButtonLabel = computed(() => {
  if (isBroadcastInProgress.value) {
    return "Broadcasting...";
  } else if (!isTranscodedVideoReady.value) {
    return "Transcoding video...";
  } else {
    return "Start broadcast";
  }
});
</script>

<template>
  <v-dialog width="500">
    <template v-slot:activator="{ props }">
      <v-btn
        stacked
        color="cyan-darken-1"
        class="toolbox-button"
        elevated-2
        @click="loadTracks"
        v-bind="props"
        :disabled="!isTranscodedVideoReady || isBroadcastInProgress"
      >
        {{ broadcastButtonLabel }}
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Dialog">
        <v-card-text>
          <v-combobox
            v-model="selectedTracks"
            label="Tracks"
            item-title="name"
            item-value="id"
            clearable
            multiple
            chips
            :items="tracks"
            :loading="isTracksLoading"
            :error="isTracksError"
          ></v-combobox>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text="Show"
            :disabled="controlsDisabled"
            @click="
              (isActive.value = false);
              show();
            "
          ></v-btn>
          <v-btn
            text="Hide"
            :disabled="controlsDisabled"
            @click="
              (isActive.value = false);
              hide();
            "
          ></v-btn>
          <v-btn
            text="Close"
            :disabled="controlsDisabled"
            @click="
              (isActive.value = false);
              clear();
            "
          ></v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
