import type { Owner } from "./owner";
import type { File, Lifecycle, Coordinates } from "./common";
import type { IntegrationPartner } from ".";

export type RecognitionMarker = {
  type: string;
  value: string;
};

export type PostRace = {
  video: {
    file: File;
  };
};

export enum ProgressItemType {
  FACT = "FACT",
  PREDICTION = "PREDICTION",
}

export type ProgressItem = {
  fromStartTimeSec: number;
  plannedFromStartTimeSec: number;
  happenedAt: Date;
  type: ProgressItemType;
  distanceM: number;
  cameraPointId?: string;
  cameraPointTrackId?: string;
  momentId?: string;
  shotId?: string;
  coordinates?: Coordinates;
  isStart: boolean;
  isFinish: boolean;
  lap: number;
  averageSpeedMPerSec: number;
  elevationGainM: number;
};

export type ParticipantIntegrations = {
  [key in IntegrationPartner]?: {
    id: string;
    raceTimeSec?: number;
    finishedAt?: Date;
  };
};

export type Participant = {
  id: string;
  bib: string;
  language: string;
  name: string;
  owner?: Owner;
  plannedTotalRaceTimeSec?: number;
  trackId: string;
  isRegistered: boolean;
  recognitionMarkers: RecognitionMarker[];
  details?: object;
  progress?: ProgressItem[];
  latestProgressFact?: ProgressItem;
  postRace?: PostRace;
  lifecycle: Lifecycle;
  isPaid?: boolean;
  integrations?: ParticipantIntegrations;
  formattedFinishTime?: string;
  officialRaceTimeSec?: number;
};
