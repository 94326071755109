import { recognitionsBaseUrl } from "../config";

export function getFrames(recognition) {
  // names are ['frames/4.jpg', 'frames/5.jpg', 'frames/1.jpg']
  // we need to sort them by parsing the number and sorting by it
  const frames = recognition.frames.map((frame) => {
    const number = frame.match(/\d+/)[0];
    return { frame, sort: parseInt(number) };
  });
  return frames.sort((a, b) => a.sort - b.sort).map((frame) => frame.frame);
}

export function getMaskedFrames(recognition) {
  const frames = recognition.framesMasked.map((frame) => {
    const number = frame.match(/\d+/)[0];
    return { frame, sort: parseInt(number) };
  });
  return frames.sort((a, b) => a.sort - b.sort).map((frame) => frame.frame);
}

export function getFrameUrl(recognition, index) {
  return `${recognitionsBaseUrl}${recognition.folder}/${getFrames(recognition)[index]}`
}

export function middleFrameIndex(recognition) {
  return Math.floor(getFrames(recognition).length / 2);
}
