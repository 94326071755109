import { toRaw } from "vue";
import { useCurrentUser } from "vuefire";

export const RECOGNITION_TYPES = [
  {
    type: "bib",
    title: "AI",
    icon: "mdi-atom-variant",
    status: "idle",
  },
  {
    type: "manual",
    title: "Manual",
    icon: "mdi-gesture-tap",
    status: "idle",
  },
];

export const RECOGNITION_STATUSES = {
  PENDING: "PENDING",
  AUTO_DONE: "AUTO_DONE",
  AUTO_DONE_ALL_DECISIVE: "AUTO_DONE_ALL_DECISIVE",
  ABORTED: "ABORTED",
  DONE: "DONE",
  MISSING_RUNNERS: "MISSING_RUNNERS",
};

export const RECOGNITION_STATUS_LABELS = {
  PENDING: "AI reco is in progress or stuck",
  AUTO_DONE: "AI recognition done: manual work needed",
  AUTO_DONE_ALL_DECISIVE: "AI recognition: all findings are decisive",
  ABORTED: "AI recognition aborted",
  DONE: "All runners identified",
  MISSING_RUNNERS: "Missing runners in recognitions",
  UNKNOWN: "Unknown recognition status",
};

export const RECOGNITION_FINISHED_STATUSES = ["AUTO_DONE", "ABORTED"];

export const RECOGNITION_STATUSES_SELECT = {
  PENDING: "PENDING",
  RECOGNITION_FINISHED: "RECOGNITION_FINISHED",
  DONE: "DONE",
  MISSING_RUNNERS: "MISSING_RUNNERS",
  AUTO_DONE_ALL_DECISIVE: "AUTO_DONE_ALL_DECISIVE",
  UNKNOWN: "UNKNOWN",
};

export const RECOGNITION_STATUS_LABELS_SELECT = {
  PENDING: "AI reco is in progress or stuck",
  RECOGNITION_FINISHED: "AI recognition finished: manual work needed",
  AUTO_DONE_ALL_DECISIVE: "AI recognition finished: all findings are decisive",
  DONE: "All runners identified",
  MISSING_RUNNERS: "Missing runners in recognitions",
  UNKNOWN: "Unknown recognition status",
};

export const getRecognitionStatusLabel = (shot) => {
  return RECOGNITION_STATUS_LABELS[shot.recognitionStatus || "UNKNOWN"];
};

export const getRecognitionStatusLabelColor = (shot) => {
  switch (shot.recognitionStatus) {
    case RECOGNITION_STATUSES.PENDING:
      return "blue-darken-1";
    case RECOGNITION_STATUSES.AUTO_DONE:
      return "blue-darken-3";
    case RECOGNITION_STATUSES.AUTO_DONE_ALL_DECISIVE:
      return "green-darken-3";
    case RECOGNITION_STATUSES.DONE:
      return "green-darken-2";
    case RECOGNITION_STATUSES.MISSING_RUNNERS:
      return "red-darken-2";
    default:
      return "red-darken-3";
  }
};

export const isClaimed = (shot) => {
  return Boolean(shot.backend?.claim?.owner?.uid);
};

export const isClaimedByMe = (shot) => {
  return shot.backend?.claim?.owner?.uid === useCurrentUser().value.uid;
};

export const claimedBy = (shot) => {
  return shot.backend?.claim?.owner?.name;
};

export const isHidden = (shot) => {
  return shot.backend?.isHidden;
};

export const getRecognitionTypeData = (recognitionType) => {
  return RECOGNITION_TYPES.find((type) => type.type === recognitionType);
};

export const getStatusClassForRecognitionType = (shot, recognitionType) => {
  if (!shot.recognitionEvents) return "";
  if (recognitionType === "bib") return getBibTypeRecognitionClass(shot);

  let finishedEvents = toRaw(shot.recognitionEvents).find(
    (event) => event.type === recognitionType && event.event === "finished"
  );
  let startedEvents = toRaw(shot.recognitionEvents).find(
    (event) => event.type === recognitionType && event.event === "started"
  );

  if (finishedEvents) return "finished";
  if (startedEvents) {
    const startedAt = startedEvents.time.toDate();
    if (new Date() - startedAt > 5 * 60 * 1000) return "timeout";
    return "started";
  }

  return "";
};

function getBibTypeRecognitionClass(shot) {
  const finishedEvents = toRaw(shot.recognitionEvents).filter(
    (event) => event.type === "bib" && event.event === "finished"
  );
  const startedEvents = toRaw(shot.recognitionEvents).filter(
    (event) => event.type === "bib" && event.event === "started"
  );
  const recognizedPersonCount = shot.recognizedPersonCount || 0;

  if (finishedEvents.length && recognizedPersonCount === finishedEvents.length) {
    return "finished";
  }
  if (startedEvents.length) {
    const startedAt = shot.lifecycle.createdAt.toDate();
    if (new Date() - startedAt > 5 * 60 * 1000) return "timeout";
    return "started";
  }
  return "";
}

export const orderRecognitionsByBib = (recognitions) => {
  const orderedRecognitionsByBib = [];

  for (const [participantId, recognition] of Object.entries(recognitions)) {
    orderedRecognitionsByBib.push({
      ...recognition,
      participantId,
    });
  }

  orderedRecognitionsByBib.sort((a, b) => {
    return a.bib - b.bib;
  });

  return orderedRecognitionsByBib;
};
