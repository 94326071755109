<script setup>
import { ref, computed, watch } from "vue";
import merge from "deepmerge";
import {
  query,
  where,
  collection,
  doc,
  arrayUnion,
  Timestamp,
  serverTimestamp,
  getDoc,
  updateDoc,
  runTransaction,
  deleteField,
} from "firebase/firestore";
import { useFirestore, useCollection } from "vuefire";
import {
  isClaimedByMe,
  claimedBy,
  getRecognitionTypeData,
  orderRecognitionsByBib,
  RECOGNITION_STATUSES,
} from "../libs/shotHelpers";
import { formatDate, formatPercentage } from "../libs/formatHelpers";

import AIRecognition from "./AIRecognition.vue";
import RecognitionDialog from "./RecognitionDialog.vue";
import RecognitionActions from "./RecognitionActions.vue";

import { shotsBaseUrl } from "../config";

const db = useFirestore();

const props = defineProps(["shotId", "race", "availableParticipants", "shotRecognitionStatus"]);
const emit = defineEmits(["close"]);

const recognitionDialog = ref(false);
const recognitionStatusDialog = ref(false);
const recognitionDialogShotId = ref(null);
const recognitionDialogShot = ref(null);
const recognitionOperations = ref([]);
const recognitionDialogAlert = ref(false);
const recognitionDialogSaving = ref(false);
const startEditingAt = ref(null);
const markers = ref({});

watch(
  () => props.shotId,
  (shotId) => {
    if (!shotId) return;
    openRecognitionDialog(shotId);
  }
);

watch(() => props.shotRecognitionStatus, (value, oldValue) => {
  if (oldValue === RECOGNITION_STATUSES.PENDING && (value === RECOGNITION_STATUSES.AUTO_DONE || value === RECOGNITION_STATUSES.AUTO_DONE_ALL_DECISIVE)) {
    refreshRecognitionsFromRemoteDocument();
  }
});

const openRecognitionDialog = async (shotId) => {
  recognitionStatusDialog.value = false;
  recognitionDialog.value = true;
  startEditingAt.value = Timestamp.now();

  const shotRef = doc(db, "races", props.race.id, "shots", shotId);
  const shotDoc = await getDoc(shotRef);
  if (!shotDoc.exists()) {
    throw "Shot does not exist!";
  }

  recognitionDialogShotId.value = shotId;
  recognitionDialogShot.value = shotDoc.data();
};

async function refreshRecognitionsFromRemoteDocument() {
  console.log("Refreshing recognitions from remote document");

  const updatedShotRef = doc(db, "races", props.race.id, "shots", recognitionDialogShotId.value);
  const updatedShotDoc = await getDoc(updatedShotRef);
  if (!updatedShotDoc.exists()) {
    throw "Shot does not exist!";
  }

  const participantsWhoseNowHaveMomentsButTheyDidntHaveBefore =
    Object.keys(recognitionDialogShot.value.recognitions || {})
      .filter((participantId) => {
        return !recognitionDialogShot.value.recognitions[participantId]?.momentId && updatedShotDoc.data().recognitions[participantId]?.momentId;
      });

  const participantsWhoNowHaveMoments = participantsWhoseNowHaveMomentsButTheyDidntHaveBefore.reduce((acc, participantId) => {
    acc[participantId] = updatedShotDoc.data().recognitions[participantId];
    return acc;
  }, {});

  // remove from recognitionOperations those who have moments now
  recognitionOperations.value = recognitionOperations.value.filter((operation) => {
    return !participantsWhoseNowHaveMomentsButTheyDidntHaveBefore.includes(operation.participantId);
  });

  recognitionDialogShot.value.recognitions = {
    ...merge(
      updatedShotDoc.data().recognitions || {},
      recognitionDialogShot.value.recognitions || {}
    ),
    ...participantsWhoNowHaveMoments
  };
}

const closeRecognitionDialog = () => {
  recognitionDialog.value = false;
};

watch(recognitionDialog, (newStateIsOpened) => {
  if (!newStateIsOpened) {
    recognitionDialogShotId.value = null;
    recognitionDialogShot.value = null;
    recognitionOperations.value = [];
    recognitionDialogSaving.value = false;
    emit("close");
  }
});

const getParticipant = (participantId) => {
  return props.availableParticipants.find((participant) => participant.id === participantId);
};

const getParticipantName = (participantId) => {
  return getParticipant(participantId)?.name;
};

const getParticipantBib = (participantId) => {
  return getParticipant(participantId)?.bib;
};

const getOriginalVideoUrl = (shot) => {
  if (shot && shot.video?.file?.name) {
    if (shot.video.file.name.includes("https://")) {
      return shot.video.file.name;
    }

    return shotsBaseUrl + props.race.id + "/" + shot.video.file.name;
  }

  return false;
};

const getVideoUrl = (shot) => {
  if (shot && shot.video?.transcodedFile?.name) {
    if (shot.video.transcodedFile.name.includes("https://")) {
      return shot.video.transcodedFile.name;
    }

    return shotsBaseUrl + props.race.id + "/" + shot.video.transcodedFile.name;
  } else if (shot && shot.video?.file?.name) {
    if (shot.video.file.name.includes("https://")) {
      return shot.video.file.name;
    }

    return shotsBaseUrl + props.race.id + "/" + shot.video.file.name;
  }

  return false;
};

const broadcastedMomentsQuery = computed(() => {
  if (recognitionDialogShotId.value) {
    return query(
      collection(db, "races", props.race.id, "moments"),
      where("shot.id", "==", recognitionDialogShotId.value)
    );
  }
});

const broadcastedMoments = useCollection(broadcastedMomentsQuery);

const getBroadcastedMoment = (momentId) => {
  return broadcastedMoments.value?.find((moment) => moment.id === momentId);
};

const recognitionHasMoment = (recognition) => {
  return Boolean(recognition.momentId);
};

const recognitionIsApproved = (recognition) => {
  return recognition.isApproved === true;
};

const recognitionIsDenied = (recognition) => {
  return recognition.isApproved === false;
};

const getRecognitionStatus = (recognition) => {
  if (recognitionHasMoment(recognition)) return "broadcasted";
  if (recognitionIsApproved(recognition)) return "approved";
  if (recognitionIsDenied(recognition)) return "denied";
  return "pending";
};

const ensureRecognitions = () => {
  if (!recognitionDialogShot.value.recognitions) {
    recognitionDialogShot.value.recognitions = {};
  }
};

const approveRecognition = (participantId) => {
  ensureRecognitions();
  recognitionDialogShot.value.recognitions[participantId].isApproved = true;
  recognitionOperations.value.push({
    type: "approve",
    participantId,
  });
};

const denyRecognition = (participantId) => {
  ensureRecognitions();
  recognitionDialogShot.value.recognitions[participantId].isApproved = false;
  recognitionOperations.value.push({
    type: "deny",
    participantId,
  });
};

const resetRecognition = (participantId) => {
  ensureRecognitions();
  delete recognitionDialogShot.value.recognitions[participantId].isApproved;
  recognitionOperations.value.push({
    type: "reset",
    participantId,
  });
};

const removeRecognition = (participantId) => {
  ensureRecognitions();
  if (!recognitionDialogShot.value.recognitions) {
    recognitionDialogShot.value.recognitions = {};
  }

  delete recognitionDialogShot.value.recognitions[participantId];
  recognitionOperations.value.push({
    type: "remove",
    participantId,
  });
};

const toggleBroadcastedMoment = (momentId, isVisible) => {
  const momentRef = doc(db, "races", props.race.id, "moments", momentId);
  updateDoc(momentRef, {
    isVisible,
    "lifecycle.updatedAt": serverTimestamp(),
  });
};

const availableBibs = computed(() => {
  return props.availableParticipants.map((participant) => {
    return {
      title: participant.title,
      value: participant.id,
    };
  });
});

const manualRecognitionCombobox = ref(null);

const addManualRecognitions = (combobox, recognitionId) => {
  ensureRecognitions();
  const recognitions = recognitionDialogShot.value.recognitions;

  combobox.forEach((selectedRecognitionItem) => {
    let participantId;

    // Allow entering manual bibs, not just selecting from the list
    if (typeof selectedRecognitionItem !== "object") {
      const participant = props.availableParticipants.find(
        (participant) => participant.bib === selectedRecognitionItem
      );
      if (!participant) return;

      participantId = participant.id;
    } else {
      // Runner selected from the list
      participantId = selectedRecognitionItem.value;
    }

    if (!recognitions[participantId]) {
      recognitions[participantId] = {
        bib: getParticipantBib(participantId),
        isApproved: true,
        recognizerResults: [],
      };
    }

    const recognition = recognitions[participantId];
    if (recognitionHasMoment(recognition)) return;

    approveRecognition(participantId);

    // if recognizerResults already has manual recognition
    // then we don't need to add it again
    if (recognitions[participantId].recognizerResults.find((result) => result.type === "manual")) return;

    recognitions[participantId].recognizerResults.push({
      type: "manual",
      time: serverTimestamp(),
      ...(recognitionId ? { recognitionId } : {}),
      confidence: 1,
    });

    recognitionOperations.value.push({
      type: "add-manual",
      participantId,
      recognitionId,
    });
  });

  manualRecognitionCombobox.value = null;
};

const createMomentsFromRecognitions = async (recognitionStatus) => {
  const shotRef = doc(db, "races", props.race.id, "shots", recognitionDialogShotId.value);
  recognitionDialogSaving.value = true;

  try {
    await runTransaction(db, async (transaction) => {
      const shotDoc = await transaction.get(shotRef);
      if (!shotDoc.exists()) {
        throw new Error("Shot does not exist!");
      }

      const shot = shotDoc.data();

      if (!isClaimedByMe(shot)) {
        throw new Error(
          `Shot was claimed by ${claimedBy(
            shot
          )} while you were editing the recognitions. Please check their changes and reclaim before saving.`
        );
      }

      const modifications = {};

      recognitionOperations.value.forEach((operation) => {
        if (shot.recognitions[operation.participantId]?.momentId) {
          return;
        }

        if (operation.type != "remove") {
          modifications[`recognitions.${operation.participantId}.bib`] = getParticipantBib(operation.participantId);
        }

        if (operation.type === "approve") {
          modifications[`recognitions.${operation.participantId}.isApproved`] = true;
        } else if (operation.type === "deny") {
          modifications[`recognitions.${operation.participantId}.isApproved`] = false;
        } else if (operation.type === "reset") {
          modifications[`recognitions.${operation.participantId}.isApproved`] = deleteField();
        } else if (operation.type === "add-manual") {
          modifications[`recognitions.${operation.participantId}.recognizerResults`] = arrayUnion({
            type: "manual",
            ...(operation.recognitionId ? { recognitionId: operation.recognitionId } : {}),
            time: Timestamp.now(),
            confidence: 1,
          });
        } else if (operation.type === "remove") {
          delete modifications[`recognitions.${operation.participantId}`];
          delete modifications[`recognitions.${operation.participantId}.bib`];
          delete modifications[`recognitions.${operation.participantId}.isApproved`];
          delete modifications[`recognitions.${operation.participantId}.recognizerResults`];
          modifications[`recognitions.${operation.participantId}`] = deleteField();
        }
      });

      modifications["recognitionEvents"] = arrayUnion(
        {
          event: "started",
          time: startEditingAt.value,
          type: "manual",
        },
        {
          event: "finished",
          time: Timestamp.now(),
          type: "manual",
        }
      );

      modifications["recognitionStatus"] = recognitionStatus;
      modifications["lifecycle.updatedAt"] = serverTimestamp();

      console.debug("Modifications on shot", modifications);
      transaction.update(shotRef, modifications);
    });
  } catch (e) {
    recognitionDialogAlert.value = e.message;
    recognitionDialogSaving.value = false;
    throw e;
  }

  closeRecognitionDialog();
};

const openRecognitionStatusDialog = () => {
  recognitionStatusDialog.value = true;
};

const closeRecognitionStatusDialog = () => {
  recognitionStatusDialog.value = false;
};

const recognitionDialogShotHasOpenRecognitions = computed(() => {
  if (!recognitionDialogShot.value) return false;
  if (!recognitionDialogShot.value.recognitions) return false;

  return Object.values(recognitionDialogShot.value.recognitions).find((recognition) => {
    return recognition.isApproved !== true && recognition.isApproved !== false;
  });
});

function addRecognitionFromAIRecognitionDialog(participant, recognitionId) {
  ensureRecognitions();

  const shotRecognitions = recognitionDialogShot.value.recognitions;
  const participantId = participant.id;

  if (!shotRecognitions[participantId]) {
    shotRecognitions[participantId] = {
      bib: participant.bib,
      isApproved: true,
      recognizerResults: [],
    };
  }

  const shotRecognition = shotRecognitions[participantId];
  if (recognitionHasMoment(shotRecognition)) return;

  approveRecognition(participantId);

  if (shotRecognitions[participantId].recognizerResults.find((result) => result.type === "manual")) return;

  shotRecognitions[participantId].recognizerResults.push({
    type: "manual",
    ...(recognitionId ? { recognitionId } : {}),
    time: serverTimestamp(),
    confidence: 1,
  });

  recognitionOperations.value.push({
    type: "add-manual",
    participantId,
    recognitionId,
  });
}

function isRecognitionLive(recognition) {
  return recognitionHasMoment(recognition) && getBroadcastedMoment(recognition.momentId)?.isVisible;
}
</script>

<template>
  <v-snackbar v-model="recognitionDialogAlert" :timeout="5000">
    {{ recognitionDialogAlert }}

    <template v-slot:actions>
      <v-btn color="primary" variant="text" @click="recognitionDialogAlert = false">Close</v-btn>
    </template>
  </v-snackbar>

  <v-dialog v-model="recognitionDialog" width="auto" persistent>
    <v-card class="recognition-dialog">
      <v-card-text>
        <v-container v-if="!recognitionDialogShot">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>
        <v-container class="item-container" v-else-if="isClaimedByMe(recognitionDialogShot)">
          <v-row>
            <v-col class="recognition-dialog__video_col flex-grow-0">
              <div class="shot-video">
                <video
                  class="rounded elevation-3"
                  v-if="getVideoUrl(recognitionDialogShot)"
                  controls
                  preload="none"
                  poster="/placeholder.jpg"
                >
                  <source type="video/mp4" :src="getVideoUrl(recognitionDialogShot)" />
                </video>
                <p v-else>could not find video</p>
              </div>
            </v-col>
            <v-col>
              <h2>
                Manage recognitions
                <v-badge :content="'Shot ID: ' + recognitionDialogShotId" inline color="teal-darken-3"></v-badge>
                <div v-if="getOriginalVideoUrl(recognitionDialogShot)">
                  <v-btn
                    class="recognition-dialog__download-original"
                    :href="getOriginalVideoUrl(recognitionDialogShot)"
                    target="_blank"
                    size="small"
                    variant="plain"
                    >Download original video</v-btn
                  >
                </div>
              </h2>
              <div class="recognition-dialog__manual">
                <div class="recognition-dialog__manual-combobox">
                  <v-combobox
                    label="Add participants manually"
                    chips
                    v-model="manualRecognitionCombobox"
                    :items="availableBibs"
                    item-text="title"
                    item-value="value"
                    @update:modelValue="addManualRecognitions(manualRecognitionCombobox)"
                    multiple
                  ></v-combobox>
                </div>
              </div>

              <AIRecognition
                :shot-id="props.shotId"
                :race="props.race"
                :shot-recognition-status="props.shotRecognitionStatus"
                :available-participants="props.availableParticipants"
                :broadcasted-moments="broadcastedMoments"
                @add-manual-recognitions="addManualRecognitions"
                @recognition-manual="addRecognitionFromAIRecognitionDialog"
                @approveRecognition="approveRecognition"
                @denyRecognition="denyRecognition"
                @resetRecognition="resetRecognition"
                @removeRecognition="removeRecognition"
                @toggleBroadcastedMoment="toggleBroadcastedMoment"
              ></AIRecognition>

              <v-table fixed-header>
                <thead>
                  <tr>
                    <th class="recognition-dialog__bib text-left">Bib</th>
                    <th class="recognition-dialog__participant text-left">Participant</th>
                    <th class="recognition-dialog__recognitions">Recognizer results</th>
                    <th class="recognition-dialog__status text-left">Status</th>
                    <th class="recognition-dialog__toolbar text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="recognition in orderRecognitionsByBib(recognitionDialogShot.recognitions)">
                    <td class="recognition-dialog__bib text-left">
                      <div class="recognition-dialog__bib__chip">{{ recognition.bib }}</div>
                    </td>
                    <td class="recognition-dialog__participant">
                      <strong>{{ getParticipantName(recognition.participantId) }}</strong>
                    </td>
                    <td class="recognition-dialog__recognitions text-left">
                      <v-chip
                        :class="{ 'background-color-green': isRecognitionLive(recognition) }"
                        class="ma-1 rounded"
                        v-for="recognitionResult in recognition.recognizerResults"
                      >
                        <v-tooltip location="top" color="green">
                          <template v-slot:activator="{ props }">
                            <div v-bind="props">
                              <RecognitionDialog
                                :race="race"
                                :recognition-id="recognitionResult.recognitionId"
                                :available-participants="availableParticipants"
                                :shot-id="shotId"
                                :broadcastedMoments="broadcastedMoments"
                                :shot-recognition="recognition"
                                @add-manual-recognitions="addManualRecognitions"
                                @recognition-manual="addRecognitionFromAIRecognitionDialog"
                                @approveRecognition="approveRecognition"
                                @denyRecognition="denyRecognition"
                                @resetRecognition="resetRecognition"
                                @removeRecognition="removeRecognition"
                                @toggleBroadcastedMoment="toggleBroadcastedMoment"
                              >
                                <v-icon
                                  class="prepend-icon"
                                  :icon="getRecognitionTypeData(recognitionResult.type)?.icon"
                                ></v-icon>
                                <div class="recognition-dialog__bib-type">
                                  {{ getRecognitionTypeData(recognitionResult.type)?.title }}
                                </div>
                              </RecognitionDialog>
                            </div>
                          </template>
                          <strong>Finished at</strong>
                          {{ recognitionResult?.time?.toDate ? formatDate(recognitionResult?.time?.toDate()) : "N/A" }}
                        </v-tooltip>
                      </v-chip>
                    </td>
                    <td class="recognition-dialog__status text-left text-capitalize">
                      {{ getRecognitionStatus(recognition) }}
                      <v-chip
                        color="cyan-darken-1"
                        v-if="
                          getRecognitionStatus(recognition) == 'broadcasted' &&
                          getBroadcastedMoment(recognition.momentId)?.isVisible
                        "
                      >
                        Live
                      </v-chip>
                      <v-chip
                        color="red-darken-1"
                        v-if="
                          getRecognitionStatus(recognition) == 'broadcasted' &&
                          !getBroadcastedMoment(recognition.momentId)?.isVisible
                        "
                      >
                        Hidden
                      </v-chip>
                    </td>
                    <td class="recognition-dialog__toolbar text-right text-no-wrap">
                      <recognition-actions
                        :recognition="recognition"
                        :broadcastedMoments="broadcastedMoments"
                        @approveRecognition="approveRecognition"
                        @denyRecognition="denyRecognition"
                        @resetRecognition="resetRecognition"
                        @removeRecognition="removeRecognition"
                        @toggleBroadcastedMoment="toggleBroadcastedMoment"
                      >
                      </recognition-actions>
                      <v-tooltip location="top" color="green">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            v-bind="props"
                            icon
                            :color="markers[recognition.participantId] ? 'blue-grey-darken-1' : 'blue-grey-darken-4'"
                            class="my-3 ml-3"
                            @click="markers[recognition.participantId] = !markers[recognition.participantId]"
                          >
                            <v-icon v-if="markers[recognition.participantId]">mdi-checkbox-intermediate</v-icon>
                            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                          </v-btn>
                        </template>
                        <div class="recognition-dialog__tooltip-title">Checkmark</div>
                        <div class="recognition-dialog__tooltip-subtitle">
                          which doesn't do anything, just a marker to help you
                        </div>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else-if="!isClaimedByMe(recognitionDialogShot)">
          Shot is claimed by {{ claimedBy(recognitionDialogShot) }}. Please grap claim before editing.
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          @click="!recognitionDialogSaving && openRecognitionStatusDialog()"
          v-if="recognitionDialogShot && isClaimedByMe(recognitionDialogShot)"
        >
          <div v-if="!recognitionDialogSaving">Save & Create Moments</div>
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        </v-btn>

        <v-btn color="secondary" @click="closeRecognitionDialog()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="recognitionStatusDialog" width="auto" persistent v-if="recognitionDialogShot">
    <v-card class="recognition-dialog__status-dialog">
      <v-card-text>
        <div class="recognition-dialog__status-dialog__title">
          Do you recognized and approved all runners in the shot?
        </div>
        <div class="recognition-dialog__status-dialog__subtitle">
          If not, mark it incomplete and we will check it later.
        </div>

        <div v-if="recognitionDialogShotHasOpenRecognitions" class="recognition-dialog__status-dialog__warning">
          It seems you have open recognitions to approve or deny!<br />
          <strong>Press cancel and finalize them before saving!</strong>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="light-green-lighten-3"
          @click="createMomentsFromRecognitions(RECOGNITION_STATUSES.DONE)"
          v-if="!recognitionDialogSaving"
        >
          All runners are covered
        </v-btn>

        <v-btn
          color="red-lighten-3"
          @click="createMomentsFromRecognitions(RECOGNITION_STATUSES.MISSING_RUNNERS)"
          v-if="!recognitionDialogSaving"
        >
          There are missing runners
        </v-btn>

        <v-progress-circular v-if="recognitionDialogSaving" indeterminate color="primary"></v-progress-circular>

        <v-btn color="secondary" @click="closeRecognitionStatusDialog()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.background-color-green {
  background-color: #388e3c;
}
</style>
