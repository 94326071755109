// https://raw.githubusercontent.com/fieldnotescommunities/ios-device-identifiers/master/ios-device-identifiers.json
import appleDevices from "./appleDevices.json";

export const getChargingColor = (device) => {
  if (device.battery.level > 60) return "green";
  if (device.battery.level < 30) return "red";
  return "white";
};

export const getDiskColor = (device) => {
  if (getFreeDiskSpace(device) > 30) return "green";
  if (getFreeDiskSpace(device) < 10) return "red";
  return "white";
};

export const getQueueColor = (device) => {
  if (device.queue.size > 10) return "red";
  if (device.queue.size < 2) return "green";
  return "white";
};

export const getName = (device) => {
  if (!device.model || !device.model.name) return "N/A";
  return appleDevices[device.model.name] ? appleDevices[device.model.name] : device.model.name;
};

export const getBatteryIcon = (device) => {
  if (!device.battery || device.battery.level == undefined || device.battery.state == undefined) return "mdi-battery";

  if (device.battery.level > 90) return "mdi-battery-check";
  if (device.battery.level < 10 && device.battery.state === "CHARGING") return "mdi-battery-charging-outline";
  if (device.battery.level < 10) return "mdi-battery-alert-variant-outline";

  const chargingIconPart = device.battery.state === "CHARGING" ? "charging-" : "";
  const batteryLevelPart = Math.round(device.battery.level / 10) * 10;

  return `mdi-battery-${chargingIconPart}${batteryLevelPart}`;
};

export const getFreeDiskSpace = (device) => {
  if (!device.storage || device.storage.freeMB == undefined) return "N/A";
  return Math.round(device.storage.freeMB / 1024);
};

export const getTotalDiskSpace = (device) => {
  if (!device.storage || device.storage.totalMB == undefined) return "N/A";
  return Math.round(device.storage.totalMB / 1024);
};
