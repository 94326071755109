<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps([
  "race",
  "shot",
]);

const dialogOpen = ref(false);
const pendingMigration = ref(false);

const migrate = async () => {
  if (pendingMigration.value) return;

  console.log("Migrating shot");
  const callable = httpsCallable(getFunctions(undefined, domain), "api/migrateShotToStart", { timeout: 540000 });
  try {
    pendingMigration.value = true;
    await callable({ raceId: props.race.id, shotId: props.shot.id });
  } catch (err) {
    console.log(err);
  }

  pendingMigration.value = false;
  dialogOpen.value = false;
};

</script>

<template>
  <v-dialog v-model="dialogOpen" max-width="500">
    <template v-slot:activator>
      <v-btn prepend-icon="mdi-content-copy" stacked class="toolbox-button" color="cyan-darken-1" @click="dialogOpen = true">
        AS START
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card title="Migrate shot as start shot">
        <v-card-text>
          <div>Do you really want to migrate this shot to start shot?</div>
          <div style="font-size: 80%; color: #ccc;">It means that this moment will be disabled from moment creation and also we will hide all related moments.</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogOpen = false">Cancel</v-btn>
          <v-btn color="primary" text @click="migrate()">
            <div v-if="!pendingMigration">Migrate</div>
            <div v-else>
              <v-progress-circular indeterminate size="small" class="mx-2" color="primary"></v-progress-circular>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
