<script setup>
import { ref, defineProps, watch, defineEmits, computed } from "vue";
import { useFirestore } from "vuefire";
import { doc, getDoc } from "firebase/firestore";
import { recognitionsBaseUrl, domain } from "../config";
import { formatDate } from "../libs/formatHelpers";
import RecognitionActions from "./RecognitionActions.vue";
import ParticipantPreview from "./ParticipantPreview.vue";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFrames, getMaskedFrames } from "../libs/recognitionHelpers";

const db = useFirestore();

const props = defineProps([
  "race",
  "recognitionId",
  "availableParticipants",
  "shotId",
  "broadcastedMoments",
  "shotRecognition",
]);

const emit = defineEmits([
  "addManualRecognitions",
  "recognitionManual",
  "approveRecognition",
  "denyRecognition",
  "resetRecognition",
  "removeRecognition",
  "toggleBroadcastedMoment",
]);

function approveRecognition(participantId) {
  emit("approveRecognition", participantId);
  closeDialog();
}

function denyRecognition(participantId) {
  emit("denyRecognition", participantId);
}

function resetRecognition(participantId) {
  emit("resetRecognition", participantId);
}

function removeRecognition(participantId) {
  emit("removeRecognition", participantId);
}

function toggleBroadcastedMoment(momentId, isVisible) {
  emit("toggleBroadcastedMoment", momentId, isVisible);
}

const dialogOpen = ref(false);

const recognition = ref(null);

const distances = ref([]);
const isDistancesLoading = ref(false);

const recognitionFrameStep = ref(0);

const recognitionTab = ref("aggregated");

const manualRecognitionCombobox = ref(null);

watch(dialogOpen, async (newDialog) => {
  if (newDialog) {
    await loadRecognition();
  }
});

async function loadRecognition() {
  const recognitionRef = doc(db, "races", props.race.id, "recognitions", props.recognitionId);
  recognition.value = (await getDoc(recognitionRef)).data();
  recognitionFrameStep.value = Math.floor(lastFrame.value * 0.55);
}

function sortBibRecognitions(bibs) {
  return bibs.sort((a, b) => {
    if (a.occurrences > b.occurrences) return -1;
    if (a.occurrences < b.occurrences) return 1;
    return 0;
  });
}

function aggregateBibRecognitions(bibs) {
  // group recognitions by bib and add occurrences
  const aggregatedBibs = {};
  bibs.forEach((bib) => {
    if (!aggregatedBibs[bib.bib]) {
      aggregatedBibs[bib.bib] = {
        bib: bib.bib,
        occurrences: 0,
        recognizers: [],
      };
    }

    aggregatedBibs[bib.bib].occurrences += bib.occurrences;

    if (bib.recognizer && !aggregatedBibs[bib.bib].recognizers.includes(bib.recognizer)) {
      aggregatedBibs[bib.bib].recognizers.push(bib.recognizer);
    }
  });

  return Object.values(aggregatedBibs);
}

function isBibAvailable(bib) {
  return props.availableParticipants.find((participant) => participant.bib === bib);
}

function sortEvents(events) {
  return events.sort((a, b) => {
    if (a.time.toMillis() > b.time.toMillis()) return 1;
    if (a.time.toMillis() < b.time.toMillis()) return -1;
    return 0;
  });
}

function emitRecognitionByBib(bib) {
  const participant = props.availableParticipants.find((participant) => participant.bib === bib);

  if (recognition.value.participant?.bib === bib) {
    setCurrentRecognition();
  } else {
    unsetCurrentRecognition();
  }

  emit("recognitionManual", participant, props.recognitionId);
  closeDialog();
}

function availableBibs() {
  return props.availableParticipants.map((participant) => {
    return {
      title: participant.title,
      value: participant.id,
    };
  });
}

function onManualRecognitionComboboxChange() {
  unsetCurrentRecognition();
  emit("addManualRecognitions", manualRecognitionCombobox.value, props.recognitionId);
  closeDialog();
}

function unsetCurrentRecognition() {
  if (recognition.value.participant?.bib) {
    if (props.shotRecognition.momentId) {
      toggleBroadcastedMoment(props.shotRecognition.momentId, false);
    } else {
      emit("denyRecognition", recognition.value.participant.id);
    }
  }
}

function setCurrentRecognition() {
  if (recognition.value.participant?.bib) {
    if (props.shotRecognition.momentId) {
      toggleBroadcastedMoment(props.shotRecognition.momentId, true);
    } else {
      emit("approveRecognition", recognition.value.participant.id);
    }
  }
}

function openDialog() {
  if (props.recognitionId) {
    dialogOpen.value = true;
  }
}

function closeDialog() {
  dialogOpen.value = false;
}

const isLoaded = computed(() => props.shotRecognition && props.recognitionId && recognition.value);

async function fetchParticipantDistancesForRecognition() {
  const callable = httpsCallable(getFunctions(undefined, "europe-west1"), "getParticipantDistancesForRecognition");
  try {
    isDistancesLoading.value = true;

    const response = await callable({
      raceId: props.race.id,
      recognitionId: props.recognitionId,
      bibs: recognition.value.bibs.map((bib) => bib.bib),
      fromDate: props.race.startAt.toDate().toISOString(),
    });

    distances.value = response.data.sort((a, b) => a.distance - b.distance);
    isDistancesLoading.value = false;
  } catch (err) {
    console.error(err);
  }
}

function bibOccurrences(bib) {
  return aggregateBibRecognitions(recognition.value.bibs).find((b) => b.bib === bib)?.occurrences;
}

const currentFrame = computed(() => {
  return `${recognitionsBaseUrl}${recognition.value.folder}/${getMaskedFrames(recognition.value)[recognitionFrameStep.value]}`;
});

const lastFrame = computed(() => {
  return recognition.value.framesMasked.length - 1;
});

</script>

<template>
  <v-dialog v-model="dialogOpen">
    <template v-slot:activator>
      <div @click="openDialog()">
        <slot></slot>
      </div>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card v-if="isLoaded">
        <v-card-text class="pa-5 d-flex flex-row">
          <div style="max-width: 300px; max-height: 100%" class="mr-6">
            <div
              style="
                position: relative;
                width: 1080px;
                aspect-ratio: 9/16;
                max-height: calc(100% - 30px);
                max-width: 300px;
                background: #000;
              "
              class="elevation-3 rounded-sm"
            >
              <img
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  max-width: 300px;
                  max-height: 100%;
                "
                :src="currentFrame"
              />

              <v-slider
                elevation="4"
                hide-details="true"
                style="width: calc(100% - 65px); position: absolute; bottom: -40px"
                class="mt-3"
                color="white"
                v-model="recognitionFrameStep"
                :max="lastFrame"
                :min="0"
                :step="1"
                thumb-label
              ></v-slider>

              <v-btn
                :href="`${recognitionsBaseUrl}${recognition.folder}/grids/default_noresize.jpg`"
                target="_blank"
                size="x-small"
                style="position: absolute; bottom: -47px; right: 0"
                color="white"
                rounded="sm"
                elevation="3"
                icon="mdi-grid"
                class="ma-2"
              >
              </v-btn>
            </div>
          </div>
          <div class="flex-grow-1">
            <h2 class="mb-4">
              <v-icon class="mt-n1">mdi-atom-variant</v-icon>
              AI Identification for <span class="text-lime">Person #{{ recognition.personIndex }}</span>
            </h2>
            <div>
              <strong>
                Appeared from
                <v-chip class="mx-1 font-weight-bold" size="small"
                  >{{ Math.round(recognition.appearedAtSec * 100) / 100 }}s</v-chip
                >
                to
                <v-chip class="mx-1 font-weight-bold" size="small"
                  >{{ Math.round(recognition.disappearedAtSec * 100) / 100 }}s</v-chip
                >
                on the video
              </strong>
            </div>
            <div class="mt-2 py-2">
              <div class="d-flex align-start" v-if="recognition.participant?.bib">
                <div>
                  <strong class="flex-grow-0">Identified bib:</strong>
                  <v-chip variant="elevated" color="primary" class="ml-2 mt-n1 rounded-sm font-weight-bold mr-3">
                    {{ recognition.participant?.bib }}
                    <participant-preview :race-id="props.race.id" :bib="recognition.participant?.bib" :recognition-id-to-omit="props.recognitionId"></participant-preview>
                  </v-chip>
                </div>
                <div class="flex-grow-0 mt-n4">
                  <recognition-actions
                    :size="'x-small'"
                    :recognition="shotRecognition"
                    :broadcastedMoments="broadcastedMoments"
                    @approveRecognition="approveRecognition"
                    @denyRecognition="denyRecognition"
                    @resetRecognition="resetRecognition"
                    @removeRecognition="removeRecognition"
                    @toggleBroadcastedMoment="toggleBroadcastedMoment"
                  ></recognition-actions>
                </div>
                <div class="flex-grow-0 mx-6">
                  <strong>OR</strong>
                </div>
                <v-combobox
                  label="Add participants manually"
                  chips
                  class="mt-n2"
                  density="compact"
                  v-model="manualRecognitionCombobox"
                  :items="availableBibs()"
                  item-text="title"
                  item-value="value"
                  @update:modelValue="onManualRecognitionComboboxChange"
                  multiple
                  hide-details="true"
                ></v-combobox>
              </div>
              <v-combobox
                  v-else
                  label="Add participants manually"
                  chips
                  class="mt-n2"
                  density="compact"
                  v-model="manualRecognitionCombobox"
                  :items="availableBibs()"
                  item-text="title"
                  item-value="value"
                  @update:modelValue="onManualRecognitionComboboxChange"
                  multiple
                  hide-details="true"
                ></v-combobox>
            </div>
            <div></div>
            <v-tabs v-model="recognitionTab" bg-color="primary" class="mt-6">
              <v-tab value="aggregated">Bibs overview</v-tab>
              <v-tab value="raw">Bibs</v-tab>
              <v-tab value="distances" @click="fetchParticipantDistancesForRecognition">Similarity check</v-tab>
              <v-tab value="events">Events</v-tab>
            </v-tabs>

            <v-window v-model="recognitionTab" class="font-weight-light">
              <v-window-item value="aggregated">
                <v-table height="350px" density="compact">
                  <thead>
                    <tr>
                      <th>Bib</th>
                      <th>Occurrences</th>
                      <th>Valid bib</th>
                      <th>AI recognizers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bib-row"
                      v-for="bibRecognition in sortBibRecognitions(aggregateBibRecognitions(recognition.bibs))"
                    >
                      <td>
                        <span>
                          {{ bibRecognition.bib }}
                          <participant-preview :race-id="props.race.id" :bib="bibRecognition.bib" :recognition-id-to-omit="props.recognitionId"></participant-preview>
                        </span>
                        <v-btn
                          v-if="isBibAvailable(bibRecognition.bib)"
                          @click="emitRecognitionByBib(bibRecognition.bib)"
                          class="add-bib-btn"
                          size="x-small"
                          icon="mdi-account-plus"
                          style="position: relative; top: -1px"
                        ></v-btn>
                      </td>
                      <td>
                        {{ bibRecognition.occurrences }}
                      </td>
                      <td>
                        <template v-if="isBibAvailable(bibRecognition.bib)">
                          <v-icon color="green">mdi-check</v-icon>
                        </template>
                        <template v-else>
                          <v-icon color="red">mdi-close</v-icon>
                        </template>
                      </td>
                      <td>
                        {{ bibRecognition.recognizers.join(", ") }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-window-item>
              <v-window-item value="raw">
                <v-table height="350px" density="compact">
                  <thead>
                    <tr>
                      <th>Bib</th>
                      <th>Occurrences</th>
                      <th>Valid bib</th>
                      <th>AI Recognizer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="bibRecognition in sortBibRecognitions(recognition.bibs)">
                      <td>
                        <span>
                          {{ bibRecognition.bib }}
                          <participant-preview :race-id="props.race.id" :bib="bibRecognition.bib" :recognition-id-to-omit="props.recognitionId"></participant-preview>
                        </span>
                      </td>
                      <td>
                        {{ bibRecognition.occurrences }}
                      </td>
                      <td>
                        <template v-if="isBibAvailable(bibRecognition.bib)">
                          <v-icon color="green">mdi-check</v-icon>
                        </template>
                        <template v-else>
                          <v-icon color="red">mdi-close</v-icon>
                        </template>
                      </td>
                      <td>
                        {{ bibRecognition.recognizer }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-window-item>
              <v-window-item value="events">
                <v-table height="350px" density="compact">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Event</th>
                      <th>Start time</th>
                      <th>End time</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="event in sortEvents(recognition.events)">
                      <td>
                        <div class="font-weight-bold">{{ event.type }}</div>
                        <div style="font-size: 80%">{{ event.subtype }}</div>
                      </td>
                      <td>
                        {{ event.event }}
                      </td>
                      <td>
                        <template v-if="event.startTime">
                          {{ formatDate(event.startTime?.toDate()) }}
                        </template>
                      </td>
                      <td>
                        {{ formatDate(event.time?.toDate()) }}
                      </td>
                      <td>
                        <template v-if="event.startTime && event.time">
                          {{ Math.round(((event.time.toDate() - event.startTime.toDate()) / 1000) * 100) / 100 }}s
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-window-item>
              <v-window-item value="distances">
                <v-progress-linear v-if="isDistancesLoading" indeterminate></v-progress-linear>
                <v-table v-else height="350px" density="compact">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Bib</th>
                      <th>Occurrences</th>
                      <th>Number of recognitions</th>
                      <th>Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bib-row" v-for="(row, i) in distances">
                      <td>
                        {{ i + 1 }}
                      </td>
                      <td>
                        <span>
                          [{{ row.bib }}]
                          <participant-preview :race-id="props.race.id" :bib="row.bib" :recognition-id-to-omit="props.recognitionId"></participant-preview>
                        </span>
                      </td>
                      <td>
                        {{ bibOccurrences(row.bib) }}
                      </td>
                      <td>
                        {{ row.numberOfMoments }}
                      </td>
                      <td>
                        {{ row.distance.toFixed(4) }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-window-item>
            </v-window>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
