import { cloneDeep } from "lodash-es";

export function modifyDeepByName(obj: any, nameTag: any, valueKey: any, newValue: any) {
  const updatedObj = cloneDeep(obj);
  return modify(updatedObj, nameTag, valueKey, newValue);
}

export function modify(obj: any, nameTag: any, valueKey: any, newValue: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item: any) => modify(item, nameTag, valueKey, newValue));
  }

  if (typeof obj === "object" && obj !== null) {
    if (obj.name && typeof obj.name === "string" && obj.name.includes(nameTag)) {
      obj[valueKey] = newValue;
    }

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj[key] = modify(obj[key], nameTag, valueKey, newValue);
      }
    }
  }

  return obj;
}
