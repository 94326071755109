<script setup>
import { computed, ref } from "vue";
import { formatDate, formatMbps } from "../libs/formatHelpers";
import {
  getStatusClassForRecognitionType,
  orderRecognitionsByBib,
  getRecognitionStatusLabel,
  getRecognitionStatusLabelColor,
} from "../libs/shotHelpers";
import { RecognitionProgress } from "../libs/recognitionProgress";
import { shotsBaseUrl } from "../config";
import StartBroadcastDialog from "./shots/StartBroadcastDialog.vue";
import ShotMigrateToStart from "./ShotMigrateToStart.vue";
import UserDeviceAsync from "./userDevice/userDeviceAsync.vue";

const props = defineProps([
  "shot",
  "availableParticipants",
  "race",
  "isClaimed",
  "isClaimedByMe",
  "claimedBy",
  "raceConfig",
]);

const emit = defineEmits(["openRecognitionDialog", "claim", "release"]);

const getShotLength = () => {
  const shotLength = props.shot.video.timing.lengthMs;
  const shotLengthMinutes = Math.floor(shotLength / 1000 / 60);
  const shotLengthSeconds = Math.floor(shotLength / 1000) % 60;

  if (shotLengthMinutes > 0) return `${shotLengthMinutes} minutes ${shotLengthSeconds} seconds`;
  else return `${shotLengthSeconds} seconds`;
};

const getParticipantName = (participantId) => {
  return props.availableParticipants.find((participant) => participant.id === participantId)?.name;
};

const emitOpenRecognitionDialog = () => {
  emit("openRecognitionDialog", props.shot.id);
};

const videoUrl = computed(() => {
  if (props.shot && props.shot.video?.transcodedFile?.name) {
    if (props.shot.video.transcodedFile.name.includes("https://")) {
      return props.shot.video.transcodedFile.name;
    }

    return shotsBaseUrl + props.race.id + "/" + props.shot.video.transcodedFile.name;
  } else if (props.shot && props.shot.video?.file?.name) {
    if (props.shot.video.file.name.includes("https://")) {
      return props.shot.video.file.name;
    }

    return shotsBaseUrl + props.race.id + "/" + props.shot.video.file.name;
  }

  return false;
});

const originalVideoUrl = computed(() => {
  if (props.shot && props.shot.video.file.name.includes("https://")) {
    return props.shot.video.file.name;
  }

  if (props.shot && props.shot.video.file?.name) {
    return shotsBaseUrl + props.race.id + "/" + props.shot.video.file.name;
  }

  return false;
});

const recognitionTimeoutSeconds =
  typeof props.raceConfig?.blockClaimForMaxSec == "number" ? props.raceConfig.blockClaimForMaxSec : 60 * 2;

const { isRecognitionInProgress, recognitionProgress } = RecognitionProgress.init({
  shotStart: props.shot.lifecycle.createdAt.toDate(),
  recognitionTimeoutSeconds,
  getStatusClassForRecognitionType: (type) => getStatusClassForRecognitionType(props.shot, type),
  isAiRecognitionDone: () => props.shot.recognitionStatus === "AUTO_DONE" || props.shot.recognitionStatus === "AUTO_DONE_ALL_DECISIVE",
});

const claim = () => {
  emit("claim");
};

const release = () => {
  emit("release");
};

const getContainerClasses = () => {
  let classes = [];

  if (props.isClaimed) {
    classes.push("claimed");
  }

  return classes.join(" ");
};

const getRecognitionStatusClass = (isApproved, momentId) => {
  if (momentId) return "shot-recognitions-founded__bib-broadcasted";
  if (isApproved === true) return "shot-recognitions-founded__bib-approved";
  if (isApproved === false) return "shot-recognitions-founded__bib-denied";
  return "shot-recognitions-founded__bib-pending";
};

const isStartShot = computed(() => props.shot.type === "START");

const getUploadTime = (shot) => {
  if (!shot.video.timing.uploadStartedAt) return "N/A";

  const uploadTime = shot.lifecycle.createdAt.toDate() - shot.video.timing.uploadStartedAt.toDate();
  return Math.floor(uploadTime / 1000);
};

const MAX_RECOGNITIONS_TO_SHOW = 10;

const recognitionsToShow = computed(() =>
  Object.fromEntries(Object.entries(props.shot.recognitions ?? {}).filter(([, value]) => value.isApproved !== false))
);

const recognitionLabels = computed(() => {
  const recognitions = orderRecognitionsByBib(recognitionsToShow.value);
  return recognitions.slice(0, MAX_RECOGNITIONS_TO_SHOW);
});

const recognitionsWereOmitted = computed(() => {
  const numberOfRecognitions = props.shot?.recognitions ? Object.keys(props.shot.recognitions).length : 0;

  return numberOfRecognitions > MAX_RECOGNITIONS_TO_SHOW;
});
</script>

<template>
  <v-sheet class="d-flex mb-3" elevation-5 rounded :class="getContainerClasses()">
    <div class="shot-video">
      <video class="rounded elevation-3" v-if="videoUrl" controls preload="none" poster="/placeholder.jpg">
        <source type="video/mp4" :src="videoUrl" />
      </video>
      <p v-else>could not find video</p>
    </div>
    <div class="shot-details flex-grow-1">
      <div class="shot-title">
        <h1>
          <v-icon icon="mdi-video-marker"></v-icon>
          {{ shot.cameraPoint?.name }} <span>video point</span>

          <v-badge :content="'ID: ' + shot.id" inline color="teal-darken-3"></v-badge>
          <v-badge
            :content="getRecognitionStatusLabel(shot)"
            inline
            :color="getRecognitionStatusLabelColor(shot)"
          ></v-badge>
        </h1>
      </div>

      <div class="shot-video-infos">
        <div>
          <strong>Videographer:</strong> {{ shot.cameraPoint?.owner?.name }}
          <v-btn
            v-if="shot.owner?.device?.deviceId"
            density="compact"
            size="default"
            icon="mdi-cellphone-text"
            class="mt-n1 ml-n1"
          >
            <v-icon icon="mdi-cellphone-text" size="x-small" color="teal"></v-icon>
            <v-tooltip activator="parent" location="bottom" open-delay="200">
              <template v-slot:default="{ isActive }">
                <user-device-async v-if="isActive" :is-active="isActive" :owner="shot.owner"></user-device-async>
              </template>
            </v-tooltip>
          </v-btn>
        </div>
        <div>
          <strong>Shot length:</strong> {{ getShotLength() }}
          <strong>from</strong>
          {{ formatDate(shot.video.timing.startAt.toDate()) }}
          <strong>to</strong> {{ formatDate(shot.video.timing.endAt.toDate()) }}

          <v-btn
            v-if="originalVideoUrl"
            :href="originalVideoUrl"
            target="_blank"
            density="compact"
            icon="mdi-download"
            class="ml-n1"
            download
          >
            <v-icon icon="mdi-download" color="teal"></v-icon>
            <v-tooltip activator="parent">Download original video</v-tooltip>
          </v-btn>
        </div>
        <div>
          <strong>Created</strong>
          <span>
            in {{ getUploadTime(shot) }} seconds
            <template v-if="!shot.owner?.device?.network?.averageSpeedInLastMinuteMbps">
              <v-icon color="teal" size="x-small" class="mt-n1 mr-2" icon="mdi-information"></v-icon>
              <v-tooltip activator="parent" open-delay="200" location="bottom" max-width="400">
                <div>Time between recording was stopped on the device and shot was created on the server</div>
              </v-tooltip>
            </template>
          </span>
          <span v-if="shot.owner?.device?.network?.averageSpeedInLastMinuteMbps">
            <strong>with</strong> {{ formatMbps(shot.owner.device.network.averageSpeedInLast2minutesMbps) }} average
            upload speed
            <v-icon color="teal" size="x-small" class="mt-n1 mr-2" icon="mdi-information"></v-icon>
            <v-tooltip activator="parent" open-delay="200" location="bottom" max-width="400">
              <div>
                <strong>Creation time:</strong> Time between recording was stopped on the device and shot was created on
                the server
              </div>
              <div><strong>Speed: </strong> Average network upload speed in last two minutes</div>
            </v-tooltip>
          </span>
        </div>
      </div>

      <div class="shot-recognitions" v-if="!isRecognitionInProgress">
        <h3>
          Recognitions
        </h3>
        <div class="shot-recognitions-founded" v-if="shot.recognitions && Object.keys(shot.recognitions).length > 0">
          <strong>We've found the following participants:</strong>
          <div>
            <v-chip
              :class="'ma-1 rounded ' + getRecognitionStatusClass(recognition.isApproved, recognition.momentId)"
              v-for="recognition in recognitionLabels"
            >
              <div class="shot-recognitions-founded__bib">
                {{ recognition.bib }}
              </div>
              <div class="shot-recognitions-founded__name">
                {{ getParticipantName(recognition.participantId) }}
              </div>
            </v-chip>
            <strong class="big-text" v-if="recognitionsWereOmitted">...</strong>
          </div>
        </div>
        <div
          class="shot-recognitions-founded shot-recognitions-founded__no-recognitions"
          v-if="!shot.recognitions || Object.keys(shot.recognitions).length == 0"
        >
          No runners recognized in this shot
        </div>
      </div>

      <div class="toolbox" v-if="isStartShot">
        <div>
          <StartBroadcastDialog :shot-id="shot.id" :race-id="props.race.id" />
        </div>
      </div>

      <div v-else>
        <div class="toolbox" v-if="isClaimedByMe && !isRecognitionInProgress">
          <div>
            <h3>Recognition</h3>
            <v-btn
              prepend-icon="mdi-target-account"
              stacked
              class="toolbox-button"
              color="cyan-darken-1"
              @click="emitOpenRecognitionDialog()"
            >
              Manage
            </v-btn>
          </div>
          <div>
            <h3>Migration</h3>
            <shot-migrate-to-start :race="race" :shot="shot"></shot-migrate-to-start>
          </div>
          <div>
            <h3>
              Claimed by you
              <span class="font-weight-light" v-if="shot.backend.claim.time">
                @ {{ formatDate(shot.backend.claim.time.toDate()) }}</span
              >
            </h3>
            <v-btn
              prepend-icon="mdi-tooltip-remove-outline"
              stacked
              color="cyan-darken-1"
              class="toolbox-button"
              @click="release()"
            >
              Release
            </v-btn>
          </div>
        </div>

        <div class="toolbox" v-else-if="isClaimed && !isRecognitionInProgress">
          <div>
            <h3>
              Claimed by <span class="text-cyan-darken-2">{{ claimedBy }}</span>
              <span class="font-weight-light" v-if="shot.backend.claim.time">
                @ {{ formatDate(shot.backend.claim.time.toDate()) }}</span
              >
            </h3>
            <v-btn stacked color="cyan-darken-1" class="toolbox-button" elevated-2 @click="claim()">
              <v-icon left>mdi-handshake</v-icon>
              Grab claim
            </v-btn>
          </div>
        </div>

        <div class="toolbox" v-else-if="!isRecognitionInProgress">
          <div>
            <v-btn stacked color="cyan-darken-1" class="toolbox-button" elevated-2 @click="claim()">
              <v-icon left>mdi-tooltip-check</v-icon>
              Claim
            </v-btn>
          </div>
        </div>

        <div class="toolbox recognition-progress" v-else>
          <h3>AI Recognition is in progress...</h3>
          <v-progress-linear
            color="light-blue"
            height="10"
            striped
            :model-value="recognitionProgress"
          ></v-progress-linear>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<style scoped>
.big-text {
  font-size: 2em; /* Adjust size as needed */
}
</style>
