<script setup>

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getCurrentUser, useFirestore } from 'vuefire';
import { query, doc, getDocFromServer, serverTimestamp, setDoc } from 'firebase/firestore';

const db = useFirestore();
const router = useRouter();

const user = ref(null);

onMounted(async () => {
  const currentUser = await getCurrentUser();
  if (currentUser) {
    user.value = currentUser;

    const userInDb = await getUserByUid(currentUser.uid);

    if (!userInDb.data()) {
        await createEntryForNewUser(currentUser);
    } else if (userInDb.data().isApproved) {
        router.push({ name: 'shots' });
    }
  }
});

const getUserByUid = async (uid) => {
    let userInDb = await getDocFromServer(
        query(doc(db, 'users', uid))
    );

    return userInDb;
};

const createEntryForNewUser = async(user) => {
    const userRef = doc(db, 'users', user.uid)
    const userData = {
        email: user.email,
        name: user.displayName,
        roles: [],
        lifecycle: {
            createdAt:serverTimestamp(),
            updatedAt:serverTimestamp()
        }
    }

    await setDoc(userRef, userData);
};

</script>

<template>
  <v-container>
    <h2>
        Hi {{ user?.displayName || 'There' }}!
    </h2>

    Thanks for signing up for ExperiRace's administration, our existing admins will grant you the necessary permissions.
  </v-container>

</template>

