import { Feature, FeatureCollection, GeometryObject, LineString, length } from "@turf/turf";

export function parseRoute(geoJSON: FeatureCollection<GeometryObject>): Feature<LineString> {
  const features = geoJSON.features.filter(isValidType).map(asLineString);

  if (features.length > 0) {
    return longestFeature(features as any) as any;
  }

  throw new Error("No valid route found in the GeoJSON");
}

function longestFeature(features: Feature<GeometryObject>[]): Feature<GeometryObject> {
  let longestFeature: Feature<GeometryObject> | undefined;
  let longestLength = 0;

  features.forEach((feature) => {
    const currentLength = length(feature);

    if (currentLength > longestLength) {
      longestLength = currentLength;
      longestFeature = feature;
    }
  });

  return longestFeature!;
}

function isValidType(feature: Feature<GeometryObject>): boolean {
  return feature.geometry?.type === "LineString" || feature.geometry?.type === "MultiLineString";
}

function asLineString(feature: any): Feature<LineString> | undefined {
  if (feature.geometry?.type === "LineString") {
    return feature as Feature<LineString>;
  } else if (feature.geometry?.type === "MultiLineString") {
    return {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: (feature.geometry as any).coordinates.flat(),
      },
    } as Feature<LineString>;
  }

  return undefined;
}
