import { Participant, ProgressItemType } from "../../../../common/domain/types";
import { formatRaceTime } from "../../../format";

export function getFinishTime(participant: Participant): string {
  const progress = participant.progress || [];
  const finishProgressItem = progress.find((progressItem) => progressItem.isFinish && progressItem.type === ProgressItemType.FACT);

  if (finishProgressItem) {
    return formatRaceTime(finishProgressItem.fromStartTimeSec);
  }

  return "";
}
