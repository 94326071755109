<script setup>

import { computed, ref, watch } from 'vue';
import { useFirestore, useCollection } from 'vuefire';
import { query, collection, orderBy, doc, updateDoc, serverTimestamp, collectionGroup } from 'firebase/firestore';
import UserDevices from './userDevice/UserDevices.vue';
import RaceSelectorDialog from './admins/RaceSelectorDialog.vue';

const db = useFirestore();

const admins = useCollection(query(collection(db, 'users'), orderBy('name', 'asc')));
const adminDevices = useCollection(collectionGroup(db, 'devices'), orderBy('deviceId', 'asc'));
const races = useCollection(query(collection(db, 'races'), orderBy('name', 'asc')));

const showWithoutRolesOnly = ref(false);

const ordering = [
  { value: "name", title: "Name", order: "asc" },
  { value: "lastAppearanceAt", title: "Last appearance", order: "desc" },
  { value: "createdAt", title: "Registration", order: "desc" }
];

const orderingBy = ref(ordering[1].value);

const devicesByLastAppearance = computed(() => {
  return [...adminDevices.value].sort((a, b) => {
    if (a.lastAppearanceAt.toDate() > b.lastAppearanceAt.toDate()) return -1;
    if (a.lastAppearanceAt.toDate() < b.lastAppearanceAt.toDate()) return 1;
    return 0;
  });
});

const getDevicesForAdmin = (admin) => {
  return devicesByLastAppearance.value.filter((device) => {
    return device.owner?.uid === admin.id;
  });
};

const adminsWithLastAppearance = computed(() => {
  if (!adminDevices.value || adminDevices.value.length === 0) return admins.value;

  return [...admins.value]
    .map((admin) => {
      return {
        id: admin.id,
        ...admin,
        roles: admin.roles.filter((role) => role !== "videographer"),
        lastAppearanceAt: adminDevices.value
          .map((a) => ({ ...a, lastAppearanceAt: a.lastAppearanceAt.toDate() }))
          .sort((a, b) => sortByDateDesc(a.lastAppearanceAt, b.lastAppearanceAt))
          .find((device) => device.owner?.uid === admin.id)?.lastAppearanceAt || 0,
      };
    });
});

const adminsToList = computed(() => {
  if (!adminDevices.value || adminDevices.value.length === 0) return admins.value;

  return [...adminsWithLastAppearance.value]
    .sort((a, b) => {
      if (orderingBy.value === 'name') {
        return a.name.localeCompare(b.name);
      } else if (orderingBy.value === 'lastAppearanceAt') {
        return sortByDateDesc(a.lastAppearanceAt, b.lastAppearanceAt);
      } else if (orderingBy.value === 'createdAt') {
        return a.lifecycle.createdAt.toDate() > b.lifecycle.createdAt.toDate() ? -1 : 1;
      }
    });
});

const sortByDateDesc = (a, b) => {
  const dateA = a instanceof Date ? a : new Date(a);
  const dateB = b instanceof Date ? b : new Date(b);
  if (dateA < dateB) return 1;
  if (dateA > dateB) return -1;
  return 0;
};

const filterAdminsWithoutRoles = (admins) => {
  return admins.filter((admin) => {
    return (!admin.roles || admin.roles.length === 0) || !showWithoutRolesOnly.value;
  });
};

const roles = ['admin', 'shot_manager', 'runner_manager'];

const changeRolesForUser = async (userUid, roles) => {
  const userRef = doc(db, 'users', userUid);
  await updateDoc(userRef, {
    roles: roles,
    'lifecycle.updatedAt': serverTimestamp(),
  });
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col class="controls-container">
        <div class="controls-container__row">
          <v-switch class="float-left control" v-model="showWithoutRolesOnly" label="Show admins waiting for approval">
          </v-switch>
          <v-select class="float-right control mr-0" :items="ordering" v-model="orderingBy" label="Order by" dense></v-select>
        </div>
      </v-col>
    </v-row>
    <v-table fixed-header>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Registration
          </th>
          <th class="text-left">
            Devices
          </th>
          <th class="text-left">
            Race Roles
          </th>
          <th class="text-left">
            Roles
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="admin in filterAdminsWithoutRoles(adminsToList)">
          <td>
            <v-tooltip location="top" color="green" scroll-strategy="close" :close-on-content-click="true" :open-on-hover="false" :open-on-click="true" content-class="selectable-tooltip">
              <template v-slot:activator="{ props }">
                <strong v-bind="props" style="cursor: pointer;">
                  {{ admin.name }}
                </strong>
              </template>
              <strong>UID</strong>
              <div>{{ admin.id }}</div>
            </v-tooltip>

            <v-tooltip location="top" color="green" scroll-strategy="close" :close-on-content-click="true" :open-on-hover="false" :open-on-click="true" content-class="selectable-tooltip">
              <template v-slot:activator="{ props }">
                <div v-bind="props" style="max-width: 300px; cursor: pointer;" class="text-truncate">{{ admin.email }}
                </div>
              </template>
              <strong>Full email</strong>
              <div>{{ admin.email }}</div>
            </v-tooltip>
          </td>
          <td>
            {{ admin.lifecycle.createdAt.toDate().toISOString().slice(0, 10) }}
          </td>
          <td>
            <user-devices :devices="getDevicesForAdmin(admin)"></user-devices>
          </td>
          <td class="text-left text-no-wrap" v-if="races && races.length > 0">
            <race-selector-dialog :admin="admin" :races="races"></race-selector-dialog>
          </td>
          <td class="text-left" style="width: 190px;">
            <v-select class="my-3" style="width: 160px;" :hide-details="true" :items="roles" v-model="admin.roles" density="compact" multiple @update:modelValue="(roles) => changeRolesForUser(admin.id, roles)">
              <template v-slot:selection="{ item, index }">
                <div v-if="admin.roles.length === 1">
                  <v-chip size="x-small">{{ item.title }}</v-chip>
                </div>
                <div v-else-if="admin.roles.length == roles.length && index === 0">
                  <v-chip size="x-small" color="green">All roles selected</v-chip>
                </div>
                <div v-else-if="admin.roles.length > 1 && index === 0">
                  <v-chip size="x-small">{{ admin.roles.length }} selected</v-chip>
                </div>
              </template>
            </v-select>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-container>
</template>

<style>
.selectable-tooltip {
  user-select: auto !important;
  cursor: default !important;
  pointer-events: all !important;
}
</style>
